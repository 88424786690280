
import NkHeading from '@/components/atoms/Heading';
import NkConfiguratorOptionExceptions from '@/components/atoms/ConfiguratorOptionExceptions.vue';
import { ref } from 'vue';

const __default__ = {
  name: 'NkConfiguratorSubOption',
  components: {
    NkHeading,
    NkConfiguratorOptionExceptions,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    exceptions: {
      type: Array,
      default: (): [] => [],
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, context: any): any {
    let more = ref(false);

    return {
      more,
      toggle() {
        context.emit('toggle');
      },
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "040e50fc": (_ctx.$theme.borderRadius.md),
  "69a17dac": (_ctx.$theme.spacing.sm),
  "69a17f6a": (_ctx.$theme.spacing.lg),
  "e5ab78a8": (_ctx.$theme.colors.grey.dark),
  "682f0f56": (_ctx.$theme.colors.primary.base),
  "e5ad49f2": (_ctx.$theme.colors.grey.base)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__