import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed h-2 w-full bottom-0 left-0 right-0 bg-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_router_outlet),
      _createVNode(_component_Settings),
      (
        _ctx.$store.getters['providers/medias/local/downloadProgress'] &&
        ![0, 100].some(
          (value) =>
            value === _ctx.$store.getters['providers/medias/local/downloadProgress'],
        )
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "h-full bg-primary-base",
              style: _normalizeStyle(`width: ${_ctx.$store.getters['providers/medias/local/downloadProgress']}%`)
            }, null, 4)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}