import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute top-0 right-0 z-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkButton = _resolveComponent("NkButton")!
  const _component_NkPanZoom = _resolveComponent("NkPanZoom")!
  const _component_NkOverlayScrollbars = _resolveComponent("NkOverlayScrollbars")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
    default: _withCtx(() => [
      _createVNode(_component_NkOverlayScrollbars, {
        disabled: _ctx.hasPanZoom,
        class: "h-screen"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["nk-dialog-slot-wrapper", { 'h-[calc(100%-8vh)]': !_ctx.overflow }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["relative", { 'w-full h-full': !_ctx.overflow }])
            }, [
              (!_ctx.hideClose)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_NkButton, {
                      size: "xs",
                      onClick: _ctx.close,
                      class: "m-xl",
                      type: "icon",
                      "icon-name": "cross",
                      "icon-size": "xs",
                      id: "closeButton",
                      ref: "closeButton"
                    }, null, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_NkPanZoom, {
                options: _ctx.panZoomOptions(),
                disabled: !_ctx.hasPanZoom,
                ref: "panZoom",
                class: "w-full h-full"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 8, ["options", "disabled"])
            ], 2)
          ], 2)
        ]),
        _: 3
      }, 8, ["disabled"])
    ]),
    _: 3
  }, 16))
}