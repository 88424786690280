import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nk-dropdownItem__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkText = _resolveComponent("NkText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.title)
        ? (_openBlock(), _createBlock(_component_NkText, {
            key: 0,
            type: "xs"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_NkText, {
        type: "xs",
        content: $props.content
      }, null, 8, ["content"])
    ])
  ], 2))
}