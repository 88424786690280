import { createProviderStoreInstance } from '@/modules/provider-manager';
import { localMediasProvider } from '@/entities/media/providers';

const providerStore = createProviderStoreInstance(localMediasProvider);
export const localMediasStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
    downloadedFiles: [],
  },
  getters: {
    ...providerStore.getters,
    toBeDownloaded(state: any, getters: any, rootState: any, rootGetters: any) {
      return rootGetters['providers/medias/collection'].filter((media: any) => {
        return !getters['collection'].find(
          (localMedia: any) => localMedia.id === media.id,
        );
      });
    },
    downloadProgress(
      state: any,
      getters: any,
      rootState: any,
      rootGetters: any,
    ) {
      return (
        (state.downloadedFiles.length /
          rootGetters['providers/medias/collection'].length) *
        100
      );
    },
  },
  mutations: {
    ...providerStore.mutations,
    addDownloadedFile(state: any, value: any) {
      state.downloadedFiles.push(value);
    },
    resetDownloadedFiles(state: any) {
      state.downloadedFiles = [];
    },
  },
  actions: {
    ...providerStore.actions,
    async downloadAll({ rootGetters }: any) {
      return localMediasProvider.downloadMedias(
        rootGetters['providers/medias/collection'],
      );
    },
    async cleanupAll({ rootGetters }: any) {
      return localMediasProvider.cleanupMedias(
        rootGetters['providers/medias/collection'],
      );
    },
    addDownloadedFile({ commit }: any, value: any) {
      commit('addDownloadedFile', value);
    },
    resetDownloadedFiles({ commit }: any) {
      commit('resetDownloadedFiles');
    },
  },
};
