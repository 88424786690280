import { createProviderStoreInstance } from '@/modules/provider-manager';
import { translationsProvider } from '@/entities/translation/providers';

const providerStore = createProviderStoreInstance(translationsProvider);

export const translationsStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
  },
  getters: {
    ...providerStore.getters,
    getByName: (state: any, getters: any) => (
      name: string,
    ): Record<any, null> | null => {
      return getters['collection'].find((item: any) => item.name === name);
    },
  },
  mutations: {
    ...providerStore.mutations,
  },
  actions: {
    ...providerStore.actions,
  },
};
