import { Provider } from '@/modules/provider-manager';
import { Directory, Encoding, Filesystem } from '@/modules/filesystem';
import axios from 'axios';
import { Promise } from 'bluebird';
import store from '@/store';
import { Deferred } from '@/utils/promise';
class LocalMediasProvider extends Provider {
  rootPath = 'medias';
  arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  async getFilesInDir() {
    let filesInDir: string[] = [];
    try {
      const { files } = await Filesystem.readdir({
        path: this.rootPath,
        directory: Directory.Data,
      });
      filesInDir = files;
    } catch (e: any) {
      console.warn(e.message);
    }
    return filesInDir;
  }
  async cleanupMedias(medias: any | any[]) {
    if (!Array.isArray(medias)) medias = [medias];
    const filesInDb: any[] = await this.readAllDocs();
    const filesToBePresent: string[] = medias.map(
      (media: any) => media.filename,
    );
    const filesInDir: string[] = await this.getFilesInDir();
    const filesToDelete = filesInDir.filter(
      (file: string) => !filesToBePresent.includes(file),
    );
    return Promise.map(
      filesToDelete,
      async (filename) => {
        return Promise.all([
          // TODO: this might fail one day lol
          new Promise(async (resolve) => {
            const doc = filesInDb.find((doc: any) => doc.filename === filename);
            if (doc) {
              await this.deleteDocs(doc);
              return resolve(filename);
            }
            return resolve(filename);
          }),
          Filesystem.deleteFile({
            path: `${this.rootPath}/${filename}`,
            directory: Directory.Data,
          }),
        ])
          .then(() => filename)
          .catch((e) => {
            console.warn(
              `Couldn't completely cleanup a file (${filename}): ${e.message}`,
            );
            return null;
          });
      },
      { concurrency: 5 },
    );
  }
  async downloadMedias(medias: any | any[]) {
    const allDocs = await this.readAllDocs();
    const downloads: {
      [key: string]: Deferred<boolean>;
    } = {};
    store.dispatch('providers/medias/local/resetDownloadedFiles');
    if (!Array.isArray(medias)) medias = [medias];
    const filesInDir: string[] = await this.getFilesInDir();
    return Promise.resolve(medias).then(async (medias) => {
      try {
        await Filesystem.mkdir({
          path: this.rootPath,
          directory: Directory.Data,
        });
      } catch (e: any) {
        console.warn(e.message);
      }
      return Promise.map(
        medias,
        (media: any) => {
          const mediaPath = `${this.rootPath}/${media.filename}`;
          return new Promise(async (resolve: any) => {
            if (!downloads[media.url]) {
              downloads[media.url] = new Deferred<boolean>();
            }
            let mediaExists = filesInDir.includes(media.filename);
            {
              // we need to make a fix to rewrite files on currently installed apps
              const mustDeleteFile = await new Promise(
                async (resolve, reject) => {
                  Filesystem.readFile({
                    path: mediaPath,
                    directory: Directory.Data,
                  })
                    .then(({ data }: any) => {
                      if (data === '_') {
                        resolve(true);
                      } else {
                        resolve(false);
                      }
                    })
                    .catch(() => resolve(false));
                },
              );
              if (mustDeleteFile) {
                await Filesystem.deleteFile({
                  path: mediaPath,
                  directory: Directory.Data,
                });
                mediaExists = false;
                console.log(`File has been deleted (${media.url})`);
              }
            }

            // TODO: UNDO THIS FOR IOS WHEN POSSIBLE
            const oldDoc = allDocs.find((item: any) => item.id === media.id);
            if (mediaExists) {
              downloads[media.url].resolve(true);
            } else {
              const res = await (axios as any).get(media.url, {
                responseType: 'arraybuffer',
                responseEncoding: 'binary',
              });
              const mediaData = `data:${
                res.headers['content-type']
              };base64,${this.arrayBufferToBase64(res.data)}`;
              await Filesystem.writeFile({
                path: mediaPath,
                data: mediaData,
                directory: Directory.Data,
                encoding: Encoding.UTF8,
              });
              downloads[media.url].resolve(true);
            }

            return downloads[media.url].promise
              .then(async () => {
                const doc = {
                  media,
                  id: media.id,
                  path: mediaPath,
                  directory: Directory.Data,
                  encoding: Encoding.UTF8,
                };
                await this.setData(doc);
                store.dispatch('providers/medias/local/addDownloadedFile', doc);
                return resolve(doc);
              })
              .catch((e: any) => {
                console.warn(
                  `Couldn't download media (${media.id}): ${e.message}`,
                  media,
                );
                store.dispatch(
                  'providers/medias/local/addDownloadedFile',
                  null,
                );
                downloads[media.url].resolve(false);
                return resolve(null);
              });
          });
        },
        { concurrency: 5 },
      );
    });
  }
}

export const localMediasProvider = new LocalMediasProvider('localMedias');
