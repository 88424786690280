
import { reactive, computed } from 'vue';
import NkHeading from '@/components/atoms/Heading';
import NkIcon from '@/components/atoms/Icon';
import NkMedia from '@/components/atoms/Media';
import NkText from '@/components/atoms/Text';

const __default__ = {
  components: {
    NkHeading,
    NkIcon,
    NkMedia,
    NkText,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
    },
    media: {
      type: String,
      required: true,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Boolean,
      default: false,
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-cardQuote': true,
        'nk-cardQuote--video': props.video,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5c66e8da": (_ctx.$theme.spacing.xl),
  "30763298": (_ctx.$theme.spacing.xl2),
  "27a45626": (_ctx.$theme.borderRadius.base),
  "9407a222": (_ctx.$theme.borderRadius.sm),
  "5c66e77d": (_ctx.$theme.spacing.md),
  "5c66e761": (_ctx.$theme.spacing.lg),
  "0061e6a7": (_ctx.$theme.transitions.fast),
  "2bf30100": (_ctx.$theme.colors.primary.base)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__