import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "h-[500px]" }
const _hoisted_2 = { class: "h-[500px]" }
const _hoisted_3 = { class: "h-28" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_card_quote = _resolveComponent("nk-card-quote")!
  const _component_nk_slider_slide = _resolveComponent("nk-slider-slide")!
  const _component_nk_media = _resolveComponent("nk-media")!
  const _component_nk_slider = _resolveComponent("nk-slider")!
  const _component_nk_page = _resolveComponent("nk-page")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_nk_page, {
            title: _ctx.$trans('testimony.title'),
            "aside-title": _ctx.$trans('testimony.asideTitle'),
            "aside-bis-title": _ctx.$trans('testimony.asideTitle2'),
            "background-position": "left",
            "has-padding": false
          }, {
            aside: _withCtx(() => [
              _createVNode(_component_nk_slider, { swiperOptions: {
              slidesPerView: 3,
              slidesPerColumn: 1,
              slidesPerColumnFill: 'row',
              spaceBetween: 10,
              breakpoints: {
                813: {
                  slidesPerColumn: 1,
                  spaceBetween: 13,
                },
                1025: {
                  slidesPerColumn: 2,
                  spaceBetween: 16,
                },
                1367: {
                  slidesPerColumn: 2,
                  spaceBetween: 20,
                },
              },
            } }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                'providers/testimonials/collection'
              ].filter((testimony) => testimony.logo), (testimony) => {
                    return (_openBlock(), _createBlock(_component_nk_slider_slide, {
                      key: testimony.id,
                      onClick: ($event: any) => (
                _ctx.$trans(testimony.translations).content ||
                testimony.image ||
                testimony.video
                  ? (_ctx.selectedTestimonyId = testimony.id)
                  : null
              )
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(["h-28 transition-opacity duration-200", {
                  'opacity-30 img--grayscale':
                    _ctx.currentTestimony.id !== testimony.id &&
                    !_ctx.$trans(testimony.translations).content &&
                    !testimony.video &&
                    !testimony.image,
                  'opacity-50':
                    _ctx.currentTestimony.id !== testimony.id &&
                    (_ctx.$trans(testimony.translations).content ||
                      testimony.video ||
                      testimony.image),
                  'cursor-pointer':
                    _ctx.$trans(testimony.translations).content ||
                    testimony.video ||
                    testimony.image,
                }])
                        }, [
                          _createVNode(_component_nk_media, {
                            media: _ctx.$trans(testimony.logo),
                            fit: "contain",
                            class: "w-full h-full"
                          }, null, 8, ["media"])
                        ], 2)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            asideBis: _withCtx(() => [
              _createVNode(_component_nk_slider, { swiperOptions: {
              slidesPerView: 3,
              spaceBetween: 20,
              centerInsufficientSlides: true,
              breakpoints: {
                813: {
                  spaceBetween: 13,
                },
                1025: {
                  spaceBetween: 16,
                },
                1367: {
                  spaceBetween: 20,
                },
              },
            } }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters['providers/awards/collection'], (award) => {
                    return (_openBlock(), _createBlock(_component_nk_slider_slide, {
                      key: award.id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_nk_media, {
                            media: _ctx.$trans(award.media),
                            fit: "contain",
                            class: "w-full h-full"
                          }, null, 8, ["media"])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_ctx.currentTestimony)
                ? (_openBlock(), _createBlock(_Transition, {
                    key: 0,
                    "enter-from-class": "opacity-0",
                    "enter-active-class": "transition-opacity duration-500 absolute top-0 inset-x-0",
                    "leave-to-class": "opacity-0",
                    "leave-active-class": "transition-opacity duration-500 absolute top-0 inset-x-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_nk_slider, {
                        key: _ctx.currentTestimony.id
                      }, {
                        default: _withCtx(() => [
                          (_ctx.$trans(_ctx.currentTestimony.translations).content)
                            ? (_openBlock(), _createBlock(_component_nk_slider_slide, {
                                key: 0,
                                class: "flex items-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_nk_card_quote, {
                                    media: _ctx.$trans(_ctx.currentTestimony.thumbnail),
                                    "image-alt": _ctx.currentTestimony.clientName,
                                    title: `${_ctx.currentTestimony.clientName}<br><b>${
                    _ctx.$trans(_ctx.currentTestimony.translations).city
                  }</b>`,
                                    content: _ctx.$trans(_ctx.currentTestimony.translations).content
                                  }, null, 8, ["media", "image-alt", "title", "content"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.currentTestimony.image)
                            ? (_openBlock(), _createBlock(_component_nk_slider_slide, {
                                key: 1,
                                class: "flex justify-center items-center"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_1, [
                                    _createVNode(_component_nk_media, {
                                      media: _ctx.$trans(_ctx.currentTestimony.image),
                                      fit: "contain",
                                      class: "w-full h-full"
                                    }, null, 8, ["media"])
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.currentTestimony.video)
                            ? (_openBlock(), _createBlock(_component_nk_slider_slide, {
                                key: 2,
                                class: "flex justify-center items-center"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_nk_media, {
                                      media: _ctx.$trans(_ctx.currentTestimony.video),
                                      fit: "contain",
                                      class: "w-full h-max-[500px]"
                                    }, null, 8, ["media"])
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title", "aside-title", "aside-bis-title"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}