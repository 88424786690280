
import { reactive, computed } from 'vue';

const __default__ = {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      validator: function (value: string): boolean {
        return ['fill', 'xs', 'sm', 'md'].indexOf(value) !== -1;
      },
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-icon': true,
        [`nk-icon--${props.size || 'fill'}`]: true,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "13bb2603": (_ctx.$theme.spacing.md),
  "13bb25e7": (_ctx.$theme.spacing.lg),
  "13bb2760": (_ctx.$theme.spacing.xl)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__