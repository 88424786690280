import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_button = _resolveComponent("nk-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_nk_navigation_list_item = _resolveComponent("nk-navigation-list-item")!
  const _component_nk_navigation_list = _resolveComponent("nk-navigation-list")!
  const _component_nk_home = _resolveComponent("nk-home")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_nk_home, {
            title: _ctx.$trans('homepage.title', true),
            "main-logo-src": require('@/assets/images/logo-etlander.svg'),
            "main-logo-alt": "ET-LANDER",
            "title-logo1-src": require('@/assets/images/logo-made-in-france.png'),
            "title-logo1-alt": "Made in France",
            "title-logo2-src": require('@/assets/images/logo-by-etesia.png'),
            "title-logo2-alt": "by Etesia",
            "background-image-src": 
          require('@/assets/images/illustration-rural.jpg')
        ,
            "foreground-image-src": 
          require('@/assets/images/illustration-urban.jpg')
        
          }, {
            tabs: _withCtx(() => [
              _createVNode(_component_nk_navigation_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                'providers/arguments/collection'
              ], (argument) => {
                    return (_openBlock(), _createBlock(_component_nk_navigation_list_item, {
                      key: argument.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: {
                  name: 'Argument',
                  query: { id: argument.id },
                }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_nk_button, {
                              color: argument.color,
                              class: "w-full whitespace-nowrap"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$trans(argument.translations).title), 1)
                              ]),
                              _: 2
                            }, 1032, ["color"])
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["title", "main-logo-src", "title-logo1-src", "title-logo2-src", "background-image-src", "foreground-image-src"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}