
import { IonContent, IonPage, IonGrid, IonCol, IonRow } from '@ionic/vue';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElCheckboxGroup,
  ElCheckbox,
  ElUpload,
} from 'element-plus';
import 'element-plus/dist/index.css';
import { defineComponent, ref, computed } from 'vue';
import { toastController } from '@ionic/vue';
import store from '@/store';
import striptags from 'striptags';
import { Directory, Encoding, Filesystem } from '@/modules/filesystem';
import { v4 as uuid } from 'uuid';
import { encode } from 'base64-arraybuffer';
export default defineComponent({
  name: 'ContactPro',
  components: {
    IonContent,
    IonPage,
    ElForm,
    ElFormItem,
    ElInput,
    ElCheckboxGroup,
    ElCheckbox,
    IonGrid,
    IonCol,
    IonRow,
    ElUpload,
  },
  ionViewDidEnter() {
    this.setMessageTemplate();
  },
  setup() {
    const formEl: any = ref(null);
    const form = ref({
      contact: {
        lastname: '',
        firstname: '',
        mail: '',
        company: '',
        phone: '',
      },
      type: [],
      content: '',
      attachments: [],
    });
    const rules = ref({
      'contact.lastname': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.firstname': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.mail': [
        { type: 'email', message: 'This is not a valid email.' },
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.company': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.phone': [
        {
          type: 'tel',
          trigger: 'blur',
          validator: (rule: any, value: string, callback: any) => {
            const valid = value.match(
              /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
            );
            if (valid) {
              return callback();
            } else if (!value && !rule.required) {
              return callback();
            }
            return callback(new Error('This is not a valid phone number.'));
          },
        },
      ],
      content: [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
    });
    const attachmentInput = ref(null);
    const dialogVisible = ref(false);
    const formNotification = ref({
      message: '',
      type: '',
    });
    const textarea = ref();
    const attachmentSizeLimit = ref(100000000);
    const formatBytes = (bytes: number, decimals = 2): string => {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };
    return {
      attachmentSizeLimit,
      textarea,
      attachmentInput,
      async addAttachment() {
        if (attachmentInput.value) {
          const currentAttachmentSize: number = form.value.attachments.reduce(
            (previousValue, currentValue: any) =>
              previousValue + currentValue.size,
            0,
          );
          const inputSize: any = Array.from(
            (attachmentInput.value as any).files,
          ).reduce(
            (previousValue, currentValue: any) =>
              previousValue + currentValue.size,
            0,
          );
          if (currentAttachmentSize + inputSize >= attachmentSizeLimit.value) {
            const toast = await toastController.create({
              message: `The total attachments size must be under 100 MB`,
              duration: 4000,
              color: 'danger',
              cssClass: 'text-center',
            });
            return await toast.present();
          }
          Array.from((attachmentInput.value as any).files).forEach(
            (file: any) => {
              const reader = new FileReader();
              reader.addEventListener('load', async () => {
                const fileName = file.name;
                const filePath = `attachments/${fileName}`;
                let uri = '';
                try {
                  await Filesystem.mkdir({
                    path: filePath,
                    directory: Directory.Data,
                    recursive: true,
                  });
                } catch (e: any) {
                  console.warn(e.message);
                }
                try {
                  const res = await Filesystem.writeFile({
                    path: filePath,
                    data: encode(reader.result as ArrayBuffer),
                    directory: Directory.Data,
                    encoding: Encoding.UTF8,
                  });
                  uri = res.uri;
                } catch (e: any) {
                  console.warn(e.message);
                }
                (form.value as any).attachments.push({
                  fileName,
                  name: `(${formatBytes(file.size, 0)}) ${file.name}`,
                  url: uri,
                  path: filePath,
                  directory: Directory.Data,
                  encoding: Encoding.UTF8,
                  size: file.size,
                  base64:
                    typeof reader.result === 'string' &&
                    reader.result.includes('base64')
                      ? reader.result
                      : encode(reader.result as ArrayBuffer),
                });
              });
              if ((window as any).CapacitorCustomPlatform)
                reader.readAsDataURL(file);
              else reader.readAsDataURL(file);
            },
          );
        }
      },
      removeAttachment(file: any, fileList: any) {
        (form.value as any).attachments = fileList;
      },
      form,
      rules,
      formEl,
      dialogVisible,
      formNotification,
      currentContact: computed(() => {
        return store.getters['providers/user/collection'][0];
      }),
    };
  },
  methods: {
    setMessageTemplate() {
      (this as any).form.content =
        striptags((this as any).currentContact?.messageTemplate) || '';
      setTimeout(() => {
        (this as any).$refs.textarea.resizeTextarea();
      }, 200);
    },
    submit() {
      (this as any).formEl
        .validate()
        .then(async () => {
          // const toast = await toastController.create({
          //   message: `Your contact request has been sent!`,
          //   duration: 6000,
          //   color: "success",
          //   cssClass: "text-center"
          // });
          // await toast.present();
          await store.dispatch('providers/contacts/add', {
            ...(this as any).form,
            user: (this as any).currentContact.id,
          });
          if (store.getters['app/isOnline']) {
            (this as any).formNotification.message = (this as any).$trans(
              'contact.form.message.success',
              true,
            );
            (this as any).formNotification.type = 'success';
          } else {
            (this as any).formNotification.message = (this as any).$trans(
              'contact.form.message.error.connection',
              true,
            );
            (this as any).formNotification.type = 'error';
          }
          (this as any).dialogVisible = true;
          (this as any).formEl.resetFields();
          (this as any).form.attachments = [];
          (this as any).setMessageTemplate();
        })
        .catch(async () => {
          // const toast = await toastController.create({
          //   message: `Your contact request could not be sent, check form fields before submitting again.`,
          //   duration: 4000,
          //   color: "danger",
          //   cssClass: "text-center"
          // });
          // await toast.present();
          (this as any).formNotification.message = (this as any).$trans(
            'contact.form.message.error.validation',
            true,
          );
          (this as any).formNotification.type = 'error';
          (this as any).dialogVisible = true;
        })
        .finally(async () => {
          await store.dispatch('providers/contacts/send');
        });
    },
  },
  mounted() {
    (this as any).setMessageTemplate();
  },
});
