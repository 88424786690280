import app from '@/app';
import { ObserveVisibility } from 'vue-observe-visibility';

app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    (vnode as any).context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  updated: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});
