import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_Video = _resolveComponent("Video")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($options.cache.src && $props.media.type === 'image')
      ? (_openBlock(), _createBlock(_component_Image, _mergeProps({ key: 0 }, _ctx.$attrs, {
          media: $props.media,
          src: $options.cache.src
        }), null, 16, ["media", "src"]))
      : _createCommentVNode("", true),
    ($options.cache.src && $props.media.type === 'video')
      ? (_openBlock(), _createBlock(_component_Video, _mergeProps({ key: 1 }, _ctx.$attrs, {
          media: $props.media,
          src: $options.cache.src
        }), null, 16, ["media", "src"]))
      : _createCommentVNode("", true)
  ], 64))
}