import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { IonicVue } from '@ionic/vue';
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import packageInfo from '../package.json';

/* Element Queries */
import ElementQueries from 'css-element-queries/src/ElementQueries';

import store from './store';

const app = createApp(App).use(store).use(IonicVue).use(router);

if (process.env.NODE_ENV === 'production') {
  Sentry.init(
    {
      app,
      dsn:
        'https://a292cf2a2f7544abba64b68973376cfa@o249022.ingest.sentry.io/6198114',
      release: `etesia-app-${packageInfo.version}`,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
          tracingOrigins: [
            'http://localhost:8080/',
            'https://catalogue.etesia.namkin.dev/',
            /^\//,
          ],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    },
    // Forward the init method from @sentry/vue
    SentryVue.init,
  );
}

router.isReady().then(() => {
  app.mount('#app');
});

window.addEventListener('load', () => {
  ElementQueries.init();
});

export default app;
