
import { computed, ref } from 'vue';
import LocaleSwitch from '@/entities/app/components/actions/LocaleSwitch.vue';
import Navigation from '@/entities/app/components/actions/Navigation.vue';
import NkHeading from '@/components/atoms/Heading';
import NkImage from '@/components/atoms/Image';
import Hammer from 'hammerjs';
import { isWeb } from '@/utils/isWeb';
const __default__ = {
  components: {
    LocaleSwitch,
    Navigation,
    NkHeading,
    NkImage,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    mainLogoSrc: {
      type: String,
      required: true,
    },
    mainLogoAlt: {
      type: String,
      required: true,
    },
    titleLogo1Src: {
      type: String,
      required: true,
    },
    titleLogo1Alt: {
      type: String,
      required: true,
    },
    titleLogo2Src: {
      type: String,
      required: true,
    },
    titleLogo2Alt: {
      type: String,
      required: true,
    },
    backgroundImageSrc: {
      type: String,
      required: true,
    },
    foregroundImageSrc: {
      type: String,
      required: true,
    },
  },

  setup(): any {
    const sliderThumbWidth = ref('0.625rem');
    const sliderGutter = ref('10%');
    const tabsHeight = ref(null);
    const foregroundImgInfos = ref(null);
    return {
      sliderThumbWidth,
      sliderGutter,
      tabsHeight,
      foregroundImgInfos,
      classes: computed(() => ({
        'nk-templateHome': true,
      })),
      hammertime: null,
    };
  },

  mounted(): void {
    const image = new Image();
    image.src = (this as any).foregroundImageSrc;
    image.addEventListener('load', () => {
      (this as any).foregroundImgInfos = {
        width: image.width,
        height: image.height,
        ratio: image.width / image.height,
      };
      (this as any).setForegroundPosition();
    });
    (this as any).tabsHeight = (this as any).$refs['tabs'].offsetHeight
      ? `${(this as any).$refs['tabs'].offsetHeight}px`
      : '4.5rem';
    if (!isWeb) (this as any).setHammertime();
    window.addEventListener('resize', (this as any).setForegroundPosition);
  },

  beforeUnmount(): void {
    window.removeEventListener('resize', (this as any).setForegroundPosition);
  },

  methods: {
    updateSlider($event: any): void {
      const position = $event.target.value;
      (this as any).$refs['foreground'].style.width = `calc(${position}% + (${
        (this as any).sliderGutter
      } + ${(this as any).sliderThumbWidth} / 2) * (1 - (${position} / 50)))`;
      (this as any).$refs['sliderThumb'].style.left = `calc(${position}% + (${
        (this as any).sliderThumbWidth
      } / 2) * (1 - (${position} / 50)))`;
      (this as any).$refs['sliderCursor'].style.left = `calc(${position}% + (${
        (this as any).sliderThumbWidth
      } / 2) * (1 - (${position} / 50)))`;
      if (position === '0')
        (this as any).$refs[
          'sliderCursorLeft'
        ].style.backgroundColor = (this as any).$theme.colors.primary.lighter;
      else
        (this as any).$refs[
          'sliderCursorLeft'
        ].style.backgroundColor = (this as any).$theme.colors.primary.light;
      if (position === '100')
        (this as any).$refs[
          'sliderCursorRight'
        ].style.backgroundColor = (this as any).$theme.colors.grey.lightest;
      else
        (this as any).$refs[
          'sliderCursorRight'
        ].style.backgroundColor = (this as any).$theme.colors.grey.dark;
    },
    setForegroundPosition(): void {
      (this as any).$refs['foreground'].style.backgroundPosition =
        window.innerWidth / window.innerHeight <
        (this as any).foregroundImgInfos.ratio
          ? `${
              (window.innerWidth -
                (this as any).foregroundImgInfos.width +
                ((this as any).foregroundImgInfos.height - window.innerHeight) *
                  (this as any).foregroundImgInfos.ratio) /
              2
            }px center`
          : '0 center';
    },
    setHammertime(): void {
      (this as any).hammertime = new Hammer.Manager((this as any).$refs.logo);
      (this as any).hammertime.add(
        new Hammer.Press({ time: 1000, event: 'longPress' }),
      );
      (this as any).hammertime.add(
        new Hammer.Tap({ taps: 2, event: 'doubletap' }),
      );
      (this as any).hammertime.on('doubletap', () => {
        window.close();
      });
      (this as any).hammertime.on('longPress', () => {
        (this as any).$store.dispatch('setSettingsOpenState', true);
      });
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "6842f664": (_ctx.$theme.spacing.xl),
  "bfc85364": (_ctx.$theme.spacing.xl2),
  "bfc85362": (_ctx.$theme.spacing.xl3),
  "20ce5d49": (_ctx.tabsHeight),
  "6842f4eb": (_ctx.$theme.spacing.lg),
  "6842f5ca": (_ctx.$theme.spacing.sm),
  "84722940": (_ctx.sliderGutter),
  "2f0f2155": (_ctx.sliderThumbWidth),
  "8a1815cc": (_ctx.$theme.borderRadius.full),
  "c3f2ef78": (_ctx.$theme.colors.secondary.base),
  "19862af4": (_ctx.$theme.colors.grey.lightest),
  "1c35a9d1": (_ctx.$theme.colors.primary.light),
  "2747b69e": (_ctx.$theme.transitions.fast),
  "6842f66b": (_ctx.$theme.spacing.xs),
  "7ce7ff68": (_ctx.$theme.colors.grey.dark)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__