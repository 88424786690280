import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_card_image = _resolveComponent("nk-card-image")!
  const _component_nk_slider_slide = _resolveComponent("nk-slider-slide")!
  const _component_nk_slider = _resolveComponent("nk-slider")!
  const _component_nk_page = _resolveComponent("nk-page")!
  const _component_nk_dialog = _resolveComponent("nk-dialog")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_nk_page, {
            title: _ctx.$trans('gallery.title'),
            "has-padding": false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_nk_slider, {
                class: "h-full",
                swiperOptions: {
            slidesPerView: 5,
            slidesPerColumn: 3,
            slidesPerColumnFill: 'row',
            spaceBetween: 25,
            breakpoints: {
              813: {
                spaceBetween: 32.5,
              },
              1025: {
                spaceBetween: 40,
              },
              1367: {
                spaceBetween: 50,
              },
            },
          }
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
              'providers/galleries/collection'
            ], (gallery, index) => {
                    return (_openBlock(), _createBlock(_component_nk_slider_slide, {
                      style: {"height":"auto"},
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_nk_card_image, {
                          thumbnail: "",
                          class: "cursor-pointer",
                          video: 
                (_ctx.$store.getters['providers/medias/getItem'](
                  _ctx.$trans(gallery.media),
                )?.type || null) === 'video'
              ,
                          onClick: ($event: any) => (_ctx.showGalleryItem(gallery)),
                          media: _ctx.$trans(gallery.media),
                          title: _ctx.$trans(gallery.translations)?.legend || ''
                        }, null, 8, ["video", "onClick", "media", "title"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["swiperOptions"])
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_nk_dialog, {
            modelValue: _ctx.dialogVisible,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = $event)),
            "has-pan-zoom": true,
            class: "h-screen"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_nk_card_image, {
                class: "h-full",
                key: _ctx.selectedGalleryItem.id,
                controls: true,
                big: "",
                autoplay: "",
                media: _ctx.$trans(_ctx.selectedGalleryItem.media),
                title: _ctx.$trans(_ctx.selectedGalleryItem.translations)?.legend || ''
              }, null, 8, ["media", "title"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}