
import { computed, ref } from 'vue';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import { merge } from 'lodash';
import NkIcon from '@/components/atoms/Icon';

SwiperCore.use([Navigation, Pagination]);

const __default__ = {
  components: {
    NkIcon,
  },
  props: {
    swiperOptions: {
      type: Object,
      default: (): any => ({}),
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
  },
  setup(): any {
    const instance = ref(null);
    const defaultOptions = ref(null);
    return {
      instance,
      defaultOptions,
      classes: computed(() => ({
        'nk-slider': true,
      })),
    };
  },
  mounted(): void {
    (this as any).defaultOptions = {
      observer: true,
      observeParents: true,
      watchOverflow: true,
      threshold: 5,
      navigation: {
        prevEl: (this as any).$refs['slider-prev'],
        nextEl: (this as any).$refs['slider-next'],
        disabledClass: 'nk-slider__navigationItem--disabled',
        hiddenClass: 'nk-slider__navigationItem--hidden',
        lockClass: 'nk-slider__navigationItem--lock',
      },
      pagination: {
        el: (this as any).$refs['slider-pagination'],
        type: 'bullets',
        clickable: true,
        bulletClass: 'nk-slider__pagination__bullet',
        bulletActiveClass: 'nk-slider__pagination__bullet--active',
        clickableClass: 'nk-slider__pagination--clickable',
        hiddenClass: 'nk-slider__pagination--hidden',
        lockClass: 'nk-slider__pagination--lock',
        modifierClass: 'nk-slider__pagination--',
      },
    };
    (this as any).instantiate(
      (this as any).$refs['swiper'],
      merge((this as any).defaultOptions, (this as any).swiperOptions),
    );
  },
  beforeUnmount(): void {
    (this as any).instance.detachEvents();
    (this as any).instance.destroy();
  },
  methods: {
    instantiate(el: any, options: any): void {
      (this as any).instance = new SwiperCore(el, options);
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "c8db5f3a": (_ctx.$theme.spacing.xs),
  "c8db5f48": (_ctx.$theme.spacing.xl),
  "66f6ebbe": (_ctx.$theme.colors.primary.base),
  "720e24a9": (_ctx.$theme.transitions.fast),
  "c8db623a": (_ctx.$theme.spacing.lg),
  "4f7e2e7e": (_ctx.$theme.colors.grey.lightest),
  "28fc17bc": (_ctx.$theme.borderRadius.full)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__