import './registerServiceWorker';
import 'intersection-observer/intersection-observer';
import '@/plugins';
import '@/app';
import '@/style';
import '@/components';
import app from '@/app';
import store from '@/store';
import router from '@/router';
console.log({ app, store, router });

(() => {
  store.dispatch('syncContent');
})();
