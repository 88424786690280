import { createProviderStoreInstance } from '@/modules/provider-manager';
import { mediasProvider } from '@/entities/media/providers';
import { localMediasStore } from '@/entities/media/store/localMedias.store';

const providerStore = createProviderStoreInstance(mediasProvider);

export const mediasStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
  },
  getters: {
    ...providerStore.getters,
  },
  mutations: {
    ...providerStore.mutations,
  },
  actions: {
    ...providerStore.actions,
  },
  modules: {
    local: localMediasStore,
  },
};
