/* doc: https://github.com/jetbridge/axios-jwt */
import {
  IAuthTokens,
  TokenRefreshRequest,
  applyAuthTokenInterceptor,
} from 'axios-jwt';
import { login, logout } from '@/services/auth';
import axios from 'axios';

// 1. Create an axios instance that you wish to apply the interceptor to
import { apiInstance } from '@/services/api';

// 2. Define token refresh function.

const requestRefresh: TokenRefreshRequest = async (
  refreshToken: string,
): Promise<IAuthTokens | string> => {
  let data = null;
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/token/refresh`,
      { refresh_token: refreshToken },
    );
    data = response.data;
  } catch (e) {
    // if refresh token expired, login again
    await logout();
    data = await login();
  }
  return data.token;
};

// 3. Add interceptor to your axios instance
applyAuthTokenInterceptor(apiInstance, { requestRefresh });
