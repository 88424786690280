import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nk-cardQuote__head" }
const _hoisted_2 = {
  key: 0,
  class: "nk-cardQuote__image"
}
const _hoisted_3 = {
  key: 0,
  class: "nk-cardQuote__image__cta"
}
const _hoisted_4 = { class: "nk-cardQuote__title" }
const _hoisted_5 = { class: "nk-cardQuote__body" }
const _hoisted_6 = { class: "nk-cardQuote__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkMedia = _resolveComponent("NkMedia")!
  const _component_NkIcon = _resolveComponent("NkIcon")!
  const _component_NkHeading = _resolveComponent("NkHeading")!
  const _component_NkText = _resolveComponent("NkText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.media)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_NkMedia, {
              controls: $props.controls,
              media: $props.media,
              class: "w-full h-full"
            }, null, 8, ["controls", "media"]),
            ($props.video)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_NkIcon, {
                    name: "play",
                    size: "sm"
                  })
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_NkHeading, {
          type: "t6",
          content: $props.title
        }, null, 8, ["content"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_NkText, {
          type: "sm",
          content: $props.content
        }, null, 8, ["content"])
      ])
    ])
  ], 2))
}