import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "configurator" }
const _hoisted_2 = { class: "configurator__view" }
const _hoisted_3 = { class: "configurator__view__colors" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "configurator__view__slider" }
const _hoisted_6 = { class: "configurator__view__slide" }
const _hoisted_7 = { class: "configurator__controls" }
const _hoisted_8 = { class: "flex gap-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_media = _resolveComponent("nk-media")!
  const _component_SliderSlide = _resolveComponent("SliderSlide")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_nk_button = _resolveComponent("nk-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_nk_configurator_view = _resolveComponent("nk-configurator-view")!
  const _component_nk_text = _resolveComponent("nk-text")!
  const _component_nk_configurator_sub_option = _resolveComponent("nk-configurator-sub-option")!
  const _component_nk_configurator_option_extended = _resolveComponent("nk-configurator-option-extended")!
  const _component_nk_configurator_option = _resolveComponent("nk-configurator-option")!
  const _component_nk_configurator_categories = _resolveComponent("nk-configurator-categories")!
  const _component_nk_configurator_controls = _resolveComponent("nk-configurator-controls")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_nk_configurator_view, null, {
                colors: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                    'providers/configurators/colors'
                  ], (color) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `color-${color.id}`,
                        class: "configurator__view__color",
                        onClick: ($event: any) => (
                      _ctx.$store.dispatch('providers/configurators/setColor', color)
                    )
                      }, [
                        _createElementVNode("div", {
                          class: "configurator__view__color__circle",
                          style: _normalizeStyle({
                        borderColor: color.color,
                        backgroundColor:
                          color.id !==
                          _ctx.$store.getters['providers/configurators/currentColor']
                            .id
                            ? color.color
                            : '',
                      })
                        }, [
                          (
                          color.id ===
                          _ctx.$store.getters['providers/configurators/currentColor']
                            .id
                        )
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "configurator__view__color__dot",
                                style: _normalizeStyle({ backgroundColor: color.color })
                              }, null, 4))
                            : _createCommentVNode("", true)
                        ], 4),
                        (
                        color.id ===
                        _ctx.$store.getters['providers/configurators/currentColor']
                          .id
                      )
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "configurator__view__color__text",
                              style: _normalizeStyle({ color: color.color })
                            }, _toDisplayString(_ctx.$trans(color.translations).title), 5))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_4))
                    }), 128))
                  ])
                ]),
                slider: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Slider, {
                      swiperOptions: { spaceBetween: 40 },
                      "has-padding": false,
                      class: "h-full"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                      'providers/configurators/faces'
                    ], (face) => {
                          return (_openBlock(), _createBlock(_component_SliderSlide, {
                            key: `face-${face.id}`
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_nk_media, {
                                  media: face.image,
                                  fit: "contain",
                                  class: "absolute inset-0",
                                  style: _normalizeStyle({ zIndex: face.zIndex })
                                }, null, 8, ["media", "style"]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                            'providers/configurators/currentLayers'
                          ], (layer) => {
                                  return (_openBlock(), _createBlock(_component_nk_media, {
                                    key: `layer-${layer.id}-${
                            layer.colorImages
                              ? _ctx.$store.getters[
                                  'providers/configurators/currentColor'
                                ].id
                              : 'default'
                          }`,
                                    media: 
                              layer.colorImages ?
                              layer.colorImages.find((image) => image.color === _ctx.$store.getters['providers/configurators/currentColor'].id && image.face === face.id).image :
                                layer.images.find(
                                  (image) => image.face === face.id,
                                ).image
                              ,
                                    fit: "contain",
                                    class: "absolute inset-0",
                                    style: _normalizeStyle({
                              zIndex: layer.images.find(
                                (image) => image.face === face.id,
                              ).zIndex,
                            })
                                  }, null, 8, ["media", "style"]))
                                }), 128))
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ])
                ]),
                close: _withCtx(() => [
                  _createVNode(_component_router_link, { to: { name: 'Home' } }, {
                    default: _withCtx(() => [
                      _createVNode(_component_nk_button, {
                        type: "icon",
                        size: "xs",
                        "icon-name": "cross",
                        "icon-size": "xs"
                      })
                    ]),
                    _: 1
                  })
                ]),
                cta: _withCtx(() => [
                  (
                  _ctx.$store.getters['providers/configurators/openedCategories']
                    .length > 0 ||
                  _ctx.$store.getters['providers/configurators/selectedOptions']
                    .length > 0 ||
                  _ctx.$store.getters['providers/configurators/currentColor'] !==
                    _ctx.$store.getters['providers/configurators/colors'][0]
                )
                    ? (_openBlock(), _createBlock(_component_nk_button, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (
                  _ctx.$store.dispatch('providers/configurators/resetConfiguration')
                ))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$trans('configurator.reset.cta', true)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_nk_configurator_controls, null, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('configurator.title', true)), 1)
                ]),
                subtitle: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('configurator.subtitle', true)), 1)
                ]),
                vehicles: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["rounded-full border border-primary-base px-4 py-2 cursor-pointer flex-1 text-center", 
                    _ctx.$store.getters[
                      'providers/configurators/selectedVehicle'
                    ] === 'MAGA'
                      ? 'bg-primary-base text-white'
                      : 'text-primary-base'
                  ]),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (
                    _ctx.$store.dispatch(
                      'providers/configurators/setVehicle',
                      'MAGA',
                    )
                  ))
                    }, " MAGA ", 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["rounded-full border border-primary-base px-4 py-2 cursor-pointer flex-1 text-center", 
                    _ctx.$store.getters[
                      'providers/configurators/selectedVehicle'
                    ] === 'N1'
                      ? 'bg-primary-base text-white'
                      : 'text-primary-base'
                  ]),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (
                    _ctx.$store.dispatch('providers/configurators/setVehicle', 'N1')
                  ))
                    }, " N1 ", 2)
                  ])
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                'providers/configurators/categories'
              ], (category) => {
                    return (_openBlock(), _createBlock(_component_nk_configurator_categories, {
                      key: `category-${category.id}`,
                      name: _ctx.$trans(category.translations).title,
                      description: _ctx.$trans(category.translations).content,
                      open: 
                  _ctx.$store.getters[
                    'providers/configurators/openedCategories'
                  ].includes(category.id)
                ,
                      onToggle: ($event: any) => (
                  _ctx.$store.dispatch(
                    'providers/configurators/toggleOpenCategory',
                    category.id,
                  )
                )
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_nk_media, {
                          class: "w-full h-full",
                          media: category.icon
                        }, null, 8, ["media"])
                      ]),
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                    'providers/configurators/getOptionsByVehicle'
                  ](category.options), (option) => {
                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                            (option.children.length > 0)
                              ? (_openBlock(), _createBlock(_component_nk_configurator_option_extended, {
                                  name: _ctx.$trans(option.translations).title,
                                  description: _ctx.$trans(option.translations).content,
                                  disabled: _ctx.$store.getters['providers/configurators/allExceptions'].find((exception) => exception.id === option.id),
                                  exceptions: _ctx.$store.getters['providers/configurators/getOptionsByIds'](option.exceptions.filter((exception) => _ctx.$store.getters['providers/configurators/selectedOptions'].includes(exception))).map((exception) => _ctx.$trans(exception.translations).title),
                                  selected: 
                        _ctx.$store.getters[
                          'providers/configurators/selectedOptions'
                        ].includes(option.id)
                      ,
                                  onToggle: ($event: any) => (
                        _ctx.$store.dispatch(
                          'providers/configurators/toggleOption',
                          option,
                        )
                      ),
                                  key: `option-${option.id}`
                                }, {
                                  icon: _withCtx(() => [
                                    _createVNode(_component_nk_media, {
                                      class: "w-full h-full",
                                      media: option.icon
                                    }, null, 8, ["media"])
                                  ]),
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                          'providers/configurators/getOptionsByVehicle'
                        ](option.children), (subOption) => {
                                      return (_openBlock(), _createBlock(_component_nk_configurator_sub_option, {
                                        key: `suboption-${subOption.id}`,
                                        name: _ctx.$trans(subOption.translations).title,
                                        disabled: _ctx.$store.getters['providers/configurators/allExceptions'].find((exception) => exception.id === subOption.id),
                                        selected: 
                            _ctx.$store.getters[
                              'providers/configurators/selectedOptions'
                            ].includes(subOption.id)
                          ,
                                        exceptions: _ctx.$store.getters['providers/configurators/getOptionsByIds'](subOption.exceptions.filter((exception) => _ctx.$store.getters['providers/configurators/selectedOptions'].includes(exception))).map((exception) => _ctx.$trans(exception.translations).title),
                                        onToggle: ($event: any) => (
                            _ctx.$store.dispatch(
                              'providers/configurators/toggleOption',
                              subOption,
                            )
                          )
                                      }, _createSlots({ _: 2 }, [
                                        (_ctx.$trans(subOption.translations).content)
                                          ? {
                                              name: "more",
                                              fn: _withCtx(() => [
                                                _createVNode(_component_nk_text, {
                                                  type: "xs2",
                                                  "inherit-color": true,
                                                  content: _ctx.$trans(subOption.translations).content
                                                }, null, 8, ["content"])
                                              ])
                                            }
                                          : undefined
                                      ]), 1032, ["name", "disabled", "selected", "exceptions", "onToggle"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["name", "description", "disabled", "exceptions", "selected", "onToggle"]))
                              : (_openBlock(), _createBlock(_component_nk_configurator_option, {
                                  name: _ctx.$trans(option.translations).title,
                                  description: _ctx.$trans(option.translations).content,
                                  disabled: _ctx.$store.getters['providers/configurators/allExceptions'].find((exception) => exception.id === option.id),
                                  exceptions: _ctx.$store.getters['providers/configurators/getOptionsByIds'](option.exceptions.filter((exception) => _ctx.$store.getters['providers/configurators/selectedOptions'].includes(exception))).map((exception) => _ctx.$trans(exception.translations).title),
                                  selected: 
                        _ctx.$store.getters[
                          'providers/configurators/selectedOptions'
                        ].includes(option.id)
                      ,
                                  onToggle: ($event: any) => (
                        _ctx.$store.dispatch(
                          'providers/configurators/toggleOption',
                          option,
                        )
                      ),
                                  key: `option-${option.id}`
                                }, {
                                  icon: _withCtx(() => [
                                    _createVNode(_component_nk_media, {
                                      class: "w-full h-full",
                                      media: option.icon
                                    }, null, 8, ["media"])
                                  ]),
                                  _: 2
                                }, 1032, ["name", "description", "disabled", "exceptions", "selected", "onToggle"]))
                          ], 64))
                        }), 256))
                      ]),
                      _: 2
                    }, 1032, ["name", "description", "open", "onToggle"]))
                  }), 128))
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}