import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_card_image = _resolveComponent("nk-card-image")!
  const _component_nk_slider_slide = _resolveComponent("nk-slider-slide")!
  const _component_nk_slider = _resolveComponent("nk-slider")!
  const _component_nk_button = _resolveComponent("nk-button")!
  const _component_nk_navigation_list_item = _resolveComponent("nk-navigation-list-item")!
  const _component_nk_navigation_list = _resolveComponent("nk-navigation-list")!
  const _component_nk_page = _resolveComponent("nk-page")!
  const _component_nk_dialog = _resolveComponent("nk-dialog")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_nk_page, {
            title: _ctx.$trans('genesis.title'),
            "has-padding": false
          }, {
            tabs: _withCtx(() => [
              _createVNode(_component_nk_navigation_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters['providers/geneses/collection'], (genesis) => {
                    return (_openBlock(), _createBlock(_component_nk_navigation_list_item, {
                      key: genesis.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_nk_button, {
                          onClick: ($event: any) => (_ctx.selectedGenesisId = genesis.id),
                          active: _ctx.currentGenesis && _ctx.currentGenesis.id === genesis.id,
                          class: "w-full whitespace-nowrap"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$trans(genesis.translations).title), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "active"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_ctx.currentGenesis)
                ? (_openBlock(), _createBlock(_component_nk_slider, {
                    key: _ctx.currentGenesis.id,
                    class: "h-full",
                    swiperOptions: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 25,
              breakpoints: {
                813: {
                  spaceBetween: 32.5,
                },
                1025: {
                  spaceBetween: 40,
                },
                1367: {
                  spaceBetween: 50,
                },
              },
            }
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentGenesis.historyDetails, (moment, index) => {
                        return (_openBlock(), _createBlock(_component_nk_slider_slide, {
                          key: index,
                          class: "flex"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_nk_card_image, {
                              thumbnail: "",
                              onClick: ($event: any) => (_ctx.showItem(moment)),
                              class: "cursor-pointer flex-1",
                              video: 
                  (_ctx.$store.getters['providers/medias/getItem'](
                    _ctx.$trans(moment.media),
                  )?.type || null) === 'video'
                ,
                              media: _ctx.$trans(moment.media),
                              title: _ctx.$trans(moment.translations).content,
                              big: ""
                            }, null, 8, ["onClick", "video", "media", "title"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["swiperOptions"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_nk_dialog, {
            modelValue: _ctx.dialogVisible,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = $event)),
            "has-pan-zoom": true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_nk_card_image, {
                big: "",
                key: _ctx.selectedItem.id,
                controls: true,
                media: _ctx.$trans(_ctx.selectedItem.media),
                title: _ctx.$trans(_ctx.selectedItem.translations).content
              }, null, 8, ["media", "title"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}