
const __default__ = {
  name: 'NkConfiguratorView',
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "6c615de1": (_ctx.$theme.spacing.xl),
  "1fca5e71": (_ctx.$theme.spacing.xl2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__