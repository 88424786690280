
import { IonImg } from '@ionic/vue';
export default {
  components: {
    IonImg,
  },
  props: {
    media: {
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
};
