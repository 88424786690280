
import NkHeading from '@/components/atoms/Heading';
import NkOverlayScrollbars from '@/components/atoms/OverlayScrollbars';

const __default__ = {
  name: 'NkConfiguratorControls',
  components: {
    NkHeading,
    NkOverlayScrollbars,
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "47895a4c": (_ctx.$theme.spacing.xl),
  "acbc2134": (_ctx.$theme.spacing.xl2),
  "478958d3": (_ctx.$theme.spacing.lg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__