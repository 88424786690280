import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-xl" }
const _hoisted_2 = {
  class: "text-primary-base hover:text-primary-dark",
  href: "https://etesia.namkin.dev/admin/reset-password",
  target: "_blank"
}
const _hoisted_3 = { class: "text-error-base" }
const _hoisted_4 = { class: "text-center mb-xs" }
const _hoisted_5 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_nk_button = _resolveComponent("nk-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_nk_page = _resolveComponent("nk-page")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_nk_page, {
            title: _ctx.$trans('login.title'),
            "aside-title": 
          _ctx.$store.state.app.deviceInfo?.platform === 'electron'
            ? _ctx.$trans('login.aside_title')
            : ''
        ,
            "background-position": "left",
            navigation: false
          }, {
            aside: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    to: { path: '/home' },
                    class: "pointer-events-auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_nk_button, { type: "file" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$trans('settings.actions.return_home', true)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.$store.state.app.deviceInfo?.platform === 'electron')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_nk_button, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close())),
                        type: "file"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$trans('settings.actions.close_application', true)), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "formEl",
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                rules: _ctx.rules,
                model: _ctx.form,
                "label-position": "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_grid, { class: "overflow-hidden" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('login.form.email.label', true),
                                prop: "username"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.username,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.username = $event)),
                                    placeholder: _ctx.$trans('login.form.email.placeholder', true),
                                    type: "email"
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('login.form.password.label', true),
                                prop: "password"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    "show-password": "",
                                    modelValue: _ctx.form.password,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.password = $event)),
                                    placeholder: 
                      _ctx.$trans('login.form.password.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.$trans('login.form.forgot_password', true)), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "-mb-xs" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { class: "flex items-center mb-xs" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, " * " + _toDisplayString(_ctx.$trans('contact.form.requiredFields', true)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, { class: "text-right mb-xs" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_nk_button, {
                                onClick: _ctx.submit,
                                type: "submit"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('login.form.submit.cta', true)), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["rules", "model"])
            ]),
            _: 1
          }, 8, ["title", "aside-title"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}