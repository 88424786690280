import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkButton = _resolveComponent("NkButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: $setup.homeLink.route
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NkButton, {
        "icon-name": $setup.homeLink.icon,
        type: "icon"
      }, null, 8, ["icon-name"])
    ]),
    _: 1
  }, 8, ["to"]))
}