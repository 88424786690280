
import { computed } from 'vue';
import NkHeading from '@/components/atoms/Heading';
import NkIcon from '@/components/atoms/Icon';
import Media from '@/components/atoms/Media';

const __default__ = {
  components: {
    NkHeading,
    NkIcon,
    Media,
  },

  props: {
    title: {
      type: String,
    },
    tel: {
      type: String,
    },
    email: {
      type: String,
    },
    imageSrc: {
      type: String,
    },
    imageLoading: {
      type: String,
      default: 'eager',
    },
    imageAlt: {
      type: String,
    },
    media: {},
  },

  setup(): any {
    return {
      classes: computed(() => ({
        'nk-cardContact': true,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "172b14e8": (_ctx.$theme.spacing.xl2),
  "2960dbcc": (_ctx.$theme.borderRadius.base),
  "10247766": (_ctx.$theme.spacing.xl),
  "102476cc": (_ctx.$theme.spacing.sm),
  "9c81e018": (_ctx.$theme.colors.primary.base),
  "6dd9828a": (_ctx.$theme.fontFamily.sans1),
  "4ac3e611": (_ctx.$theme.fontSize.sm),
  "f9849bfc": (_ctx.$theme.colors.secondary.base),
  "102475ed": (_ctx.$theme.spacing.lg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__