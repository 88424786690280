import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nk-button__content" }
const _hoisted_2 = {
  key: 0,
  class: "nk-button__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkIcon = _resolveComponent("NkIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent($props.tag), {
    class: _normalizeClass($setup.classes)
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString($props.content), 1)
        ])
      ]),
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        ($props.iconName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_component_NkIcon, {
                name: $props.iconName,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}