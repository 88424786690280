import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent($props.tag), {
    class: _normalizeClass($setup.classes)
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        ($props.content)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              innerHTML: $props.content
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}