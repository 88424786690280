const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1400px',
  xl3: '1600px',
};

const spacing = {
  xs3: '0.125rem',
  xs2: '0.25rem',
  xs: '0.5rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
  xl2: '3rem',
  xl3: '4rem',
};

const colors = {
  primary: {
    dark: '#416D29',
    base: '#6CB644',
    light: '#89C569',
    lighter: '#E2F0DA',
    lightest: '#F0F8EC',
  },
  secondary: {
    base: '#17233D',
    lighter: '#A2A7B1',
  },
  tertiary: {
    dark: '#296DF1',
    base: '#5D96F6',
    light: '#7DB1FA',
    lighter: '#D4E8FE',
    lightest: '#E9F3FF',
  },
  quaternary: {
    dark: '#16240E',
    base: '#2B491B',
    lighter: '#A6B29F',
  },
  quinary: {
    dark: '#071D73',
    base: '#0D2A8B',
    lighter: '#8695C5',
  },
  grey: {
    dark: '#515A6E',
    base: '#808695',
    light: '#B9B9BA',
    lightest: '#DCDEE2',
  },
  error: {
    base: '#FF3C31',
    light: '#F94E4D',
  },
};

const themeConfig = {
  screens,
  bootstrapBreakpoints: {
    xs: 0,
    ...screens,
  },
  bootstrapMaxWidths: screens,
  colors: {
    ...colors,
  },
  components: {},
  gutters: {
    ...spacing,
  },
  spacing: {
    ...spacing,
  },
  transitions: {
    fast: 'all 0.1s ease-in',
    base: 'all 0.2s ease-in',
  },
  backgroundColor: {
    ...colors,
  },
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {
    ...colors,
  },
  borderRadius: {
    xs: '0.75rem',
    sm: '1.125rem',
    base: '1.25rem',
    md: '1.5625rem',
    lg: '2.5rem',
    full: '9999px',
  },
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    sans1: [
      'Josefin Sans',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    sans2: [
      'Montserrat',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    sans3: [
      'Assistant',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
  },
  fontSize: {
    xs2: '0.75rem',
    xs: '0.875rem',
    sm: '1rem',
    base: '1.125rem',
    md: '1.25rem',
    lg: '1.375rem',
    xl: '1.5rem',
    xl2: '1.75rem',
    xl3: '2.125rem',
    xl4: '2.625rem',
    xl5: '3.125rem',
    xl6: '3.875rem',
  },
  fontWeight: {},
  height: {},
  inset: {},
  letterSpacing: {},
  lineHeight: {
    xs2: '1',
    xs: '1.1',
    sm: '1.2',
    base: '1.35',
    md: '1.45',
    lg: '1.55',
  },
  listStyleType: {},
  margin: {},
  maxHeight: {},
  maxWidth: {},
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  width: {},
  zIndex: {},
};

module.exports = themeConfig;
