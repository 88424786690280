import { createStore } from 'vuex';
import providers from '@/store/providers';
import { appStorePlugin } from '@/modules/app';
import { i18nStorePlugin } from '@/modules/i18n';
import { settingsStore as settings } from '@/store/settings.store';
import { Promise } from 'bluebird';
import { toastController } from '@ionic/vue';

export default createStore({
  state: {
    navigationOpen: false,
    syncing: false,
    downloading: false,
    settingsOpen: false,
  },
  getters: {
    navigationOpen(state: any) {
      return state.navigationOpen;
    },
  },
  mutations: {
    toggleNavigationOpen(state: any) {
      state.navigationOpen = !state.navigationOpen;
    },
    setSyncing(state: any, value: boolean) {
      state.syncing = value;
    },
    setDownloading(state: any, value: boolean) {
      state.downloading = value;
    },
    setSettingsOpenState(state: any, value: boolean) {
      state.settingsOpen = value;
    },
  },
  actions: {
    setSettingsOpenState({ commit }: any, value: boolean) {
      commit('setSettingsOpenState', value);
    },
    setSyncing({ commit }: any, value: boolean) {
      commit('setSyncing', value);
    },
    setDownloading({ commit }: any, value: boolean) {
      commit('setDownloading', value);
    },
    toggleNavigationOpen({ commit }: any) {
      commit('toggleNavigationOpen');
    },
    async downloadMedias({ dispatch, rootGetters, state }) {
      if (state.downloading) {
        return Promise.reject(new Error('Already Downloading Content.'));
      }
      const toast = await toastController.create({
        message: 'Download has started',
        duration: 3000,
        cssClass: 'text-center',
        color: 'primary',
      });
      await toast.present();
      dispatch('setDownloading', true);
      return new Promise(async (resolve) => {
        if (rootGetters['settings/all'].offlineMode) {
          await dispatch('providers/medias/local/downloadAll');
        }
        await dispatch('providers/medias/local/cleanupAll');

        // regardless of errors update the local media store
        dispatch('providers/medias/local/fetch');
        return resolve(true);
      }).finally(() => {
        dispatch('setDownloading', false);
      });
    },
    async syncContent({ dispatch, state }) {
      if (state.syncing) {
        return Promise.reject(new Error('Already Syncing Content.'));
      }
      const toast = await toastController.create({
        message: 'Sync has started',
        duration: 3000,
        cssClass: 'text-center',
        color: 'primary',
      });
      await toast.present();
      dispatch('setSyncing', true);
      return new Promise(async (resolve) => {
        await dispatch('app/updateNetworkStatus');
        await dispatch('providers/translations/fetch');
        try {
          await dispatch('providers/contacts/send');
        } catch (e) {
          console.warn(`Couldn't send current contacts`);
        }
        Promise.map(
          [
            'providers/arguments/fetch',
            'providers/medias/fetch',
            'providers/galleries/fetch',
            'providers/geneses/fetch',
            'providers/specifications/fetch',
            'providers/testimonials/fetch',
            'providers/awards/fetch',
            'providers/defaultContact/fetch',
            'providers/configurators/fetch',
            'providers/contacts/fetch',
            'providers/user/fetch',
            'providers/faq/fetch',
          ],
          (action: string) => dispatch(action),
          { concurrency: 10 },
        ).then(async () => {
          await dispatch('downloadMedias');
          return resolve(true);
        });
      }).finally(() => dispatch('setSyncing', false));
    },
  },
  modules: {
    providers,
    settings,
  },
  plugins: [appStorePlugin, i18nStorePlugin],
});
