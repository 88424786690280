
export default {
  props: {
    controls: {
      type: Boolean,
      default: true,
    },
    media: {
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
};
