import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nk-configuratorControls" }
const _hoisted_2 = { class: "nk-configuratorControls__title" }
const _hoisted_3 = { class: "nk-configuratorControls__subtitle" }
const _hoisted_4 = { class: "nk-configuratorControls__vehicles" }
const _hoisted_5 = { class: "nk-configuratorControls__content" }
const _hoisted_6 = { class: "nk-configuratorControls__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_heading = _resolveComponent("nk-heading")!
  const _component_NkOverlayScrollbars = _resolveComponent("NkOverlayScrollbars")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_nk_heading, { type: "t2" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "title")
        ]),
        _: 3
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_nk_heading, {
        type: "t3",
        color: "primary"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "subtitle")
        ]),
        _: 3
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "vehicles")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_NkOverlayScrollbars, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _: 3
      })
    ])
  ]))
}