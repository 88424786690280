import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "nk-configuratorCategories__icon" }
const _hoisted_3 = { class: "nk-configuratorCategories__name" }
const _hoisted_4 = { class: "nk-configuratorCategories__toggler" }
const _hoisted_5 = { class: "nk-configuratorCategories__options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_heading = _resolveComponent("nk-heading")!
  const _component_nk_text = _resolveComponent("nk-text")!
  const _component_nk_icon = _resolveComponent("nk-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nk-configuratorCategories", $props.open ? 'open' : ''])
  }, [
    _createElementVNode("div", {
      class: "nk-configuratorCategories__wrapper",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggle && $setup.toggle(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "icon")
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_nk_heading, { type: "t4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_nk_text, {
            type: "xs2",
            content: $props.description
          }, null, 8, ["content"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_nk_icon, { name: "toggler" })
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default")
    ], 512), [
      [_vShow, $props.open]
    ])
  ], 2))
}