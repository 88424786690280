
import { ref } from 'vue';
import { v4 as uuid } from 'uuid';
import NkHeading from '@/components/atoms/Heading';

const __default__ = {
  components: {
    NkHeading,
  },

  inject: ['shared'],

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  setup(): any {
    const id = uuid();
    const content = ref(null);
    const contentHeight = ref(null);
    const hasContent = ref(false);
    const mutationObserver = null;
    return {
      id,
      content,
      contentHeight,
      hasContent,
      mutationObserver,
    };
  },

  computed: {
    open(): boolean {
      return (this as any).shared.selectedId === (this as any).id;
    },
    classes(): any {
      return {
        'nk-dropdown': true,
        'nk-dropdown--open': (this as any).open,
      };
    },
  },

  mounted(): void {
    (this as any).setHasContent((this as any).content);
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        (this as any).setContentHeight(entry.target);
      }
    });
    (this as any).mutationObserver = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        (this as any).setHasContent(mutation.target);
      }
    });
    if (Array.from((this as any).content.children).length > 0) {
      resizeObserver.observe((this as any).content.children[0]);
    }
    (this as any).mutationObserver.observe((this as any).content, {
      attributes: true,
      childList: true,
    });
  },

  beforeUnmount(): void {
    (this as any).mutationObserver.disconnect();
  },

  methods: {
    setContentHeight(target: any): void {
      if (target) {
        (this as any).contentHeight = `${target.offsetHeight}px`;
      }
    },
    setHasContent(target: any): void {
      (this as any).hasContent = Array.from(target.children).length > 0;
    },
    handleClick(): void {
      if ((this as any).shared.selectedId === (this as any).id) {
        (this as any).shared.selectedId = null;
        (this as any).$emit('close');
      } else {
        (this as any).shared.selectedId = (this as any).id;
        (this as any).$emit('open');
      }
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "205633d5": (_ctx.$theme.spacing.md),
  "2bb817a8": (_ctx.$theme.colors.primary.base),
  "5dcc90ff": (_ctx.$theme.transitions.fast),
  "205633b9": (_ctx.$theme.spacing.lg),
  "7089b74a": (_ctx.contentHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__