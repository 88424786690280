import { set, get, defaultsDeep } from 'lodash';
import { isWeb } from '@/utils/isWeb';
const storedSettings = localStorage.getItem('settings');

const defaultSettings = {
  offlineMode: !isWeb || process.env.NODE_ENV === 'development',
};

export const settingsStore = {
  namespaced: true,
  state: storedSettings
    ? defaultsDeep(JSON.parse(storedSettings), defaultSettings)
    : defaultSettings,
  mutations: {
    set(state: any, { property, value }: any) {
      const result = set(state, property, value);
      localStorage.setItem('settings', JSON.stringify(result));
    },
  },
  getters: {
    ['all'](state: any) {
      return state;
    },
    ['get']: (state: any) => (property: string) => {
      return get(state, property);
    },
  },
  actions: {
    ['set']({ commit }: any, value: { property: 'string'; value: any }) {
      commit('set', value);
    },
  },
};
