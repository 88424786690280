import {
  Filesystem as defaultFilesystem,
  FilesystemPlugin,
} from '@capacitor/filesystem';

export const createWritableArrayBuffer = (data: any) =>
  (window as any).CapacitorCustomPlatform ||
  data instanceof Blob ||
  typeof data === 'string'
    ? data
    : new Blob([data]);
export const convertFileDataToBlob = (data: any) =>
  data instanceof Blob ? data : new Blob([data]);
export const convertBase64DataToBlob = (data: any) =>
  fetch(data).then((res) => res.blob());
export * from '@capacitor/filesystem';
export const Filesystem: FilesystemPlugin =
  (window as any).CapacitorCustomPlatform?.plugins.Filesystem ||
  defaultFilesystem;
