import app from '@/app';
import store from '@/store';
import striptags from 'striptags';

const $trans = (translatable: any, removeTags = false) => {
  const trans = store.getters['i18n/trans'](translatable);
  return removeTags && typeof trans === 'string' ? striptags(trans) : trans;
};

app.provide('$trans', $trans);

app.config.globalProperties.$trans = $trans;
