
import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  toastController,
} from '@ionic/vue';
import { ElForm, ElFormItem, ElInput } from 'element-plus';
import { defineComponent, ref } from 'vue';
import NkButton from '@/components/atoms/Button';
// import {toastController} from '@ionic/vue';
import { api } from '@/services';
import axios from 'axios';

export default defineComponent({
  name: 'Contact',
  components: {
    IonContent,
    IonPage,
    ElForm,
    ElFormItem,
    ElInput,
    IonGrid,
    IonCol,
    IonRow,
    NkButton,
  },
  setup() {
    const formEl: any = ref(null);
    const form = ref({
      username: '',
      password: '',
    });
    const rules = ref({
      username: [
        { type: 'email', message: 'This is not a valid email.' },
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      password: [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
    });
    return {
      form,
      rules,
      formEl,
    };
  },
  methods: {
    close() {
      return window.close();
    },
    submit() {
      (this as any).formEl
        .validate()
        .then(async () => {
          return axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/login_check`,
            (this as any).form,
          );
        })
        .then(({ data }: any) => {
          const user = (this as any).parseJwt(data.token);
          return api(`/user/${user.id}`);
        })
        .then(async ({ data }: any) => {
          await (this as any).$store
            .dispatch('providers/user/deleteAllDocs')
            .then(() => {
              return (this as any).$store.dispatch(
                'providers/user/setData',
                data,
              );
            });
          const toast = await toastController.create({
            message: `Logged in as ${this.form.username}.`,
            duration: 4000,
            color: 'success',
            cssClass: 'text-center',
          });
          await toast.present();
          (this as any).$refs.formEl.resetFields();
          (this as any).$router.push({ name: 'Home' });

          // download medias with for user pfp
          (this as any).$store.dispatch('downloadMedias');
          return Promise.resolve();
        })
        .catch(async () => {
          const toast = await toastController.create({
            message: `An error occured. Please check form fields before submitting again.`,
            duration: 4000,
            color: 'danger',
            cssClass: 'text-center',
          });
          await toast.present();
        });
    },
    parseJwt(token: string) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    },
  },
});
