import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nk-configurator-view" }
const _hoisted_2 = { class: "nk-configurator-view__top" }
const _hoisted_3 = { class: "nk-configurator-view__logo" }
const _hoisted_4 = { class: "nk-configurator-view__slider" }
const _hoisted_5 = { class: "nk-configurator-view__close" }
const _hoisted_6 = { class: "nk-configurator-view__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_image = _resolveComponent("nk-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_nk_image, {
          src: require('@/assets/images/logo-etlander-full.png'),
          alt: "Etesia",
          "object-fit": "contain",
          "object-position": "0% 50%"
        }, null, 8, ["src"])
      ]),
      _renderSlot(_ctx.$slots, "colors")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "slider")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "close")
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "cta")
    ])
  ]))
}