
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent, ref } from 'vue';

import NkConfiguratorView from '@/components/templates/ConfiguratorView.vue';

import NkConfiguratorControls from '@/components/templates/ConfiguratorControls.vue';
import NkConfiguratorCategories from '@/components/organisms/ConfiguratorCategories.vue';
import NkConfiguratorOption from '@/components/molecules/ConfiguratorOption.vue';
import NkConfiguratorOptionExtended from '@/components/molecules/ConfiguratorOptionExtended.vue';
import NkConfiguratorSubOption from '@/components/atoms/ConfiguratorSubOption.vue';

import Slider from '@/components/organisms/Slider.vue';
import SliderSlide from '@/components/organisms/SliderSlide.vue';

const __default__ = defineComponent({
  name: 'Configurator',
  components: {
    IonContent,
    IonPage,
    NkConfiguratorControls,
    NkConfiguratorCategories,
    NkConfiguratorOption,
    NkConfiguratorOptionExtended,
    NkConfiguratorSubOption,
    NkConfiguratorView,
    Slider,
    SliderSlide,
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "cbe3e432": (_ctx.$theme.spacing.xl2),
  "5fcee7ab": (_ctx.$theme.spacing.xl),
  "5fcee7b2": (_ctx.$theme.spacing.xs),
  "1f3101e6": (_ctx.$theme.fontSize.xs)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__