import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nk-tile__image" }
const _hoisted_2 = { class: "nk-tile__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkImage = _resolveComponent("NkImage")!
  const _component_NkText = _resolveComponent("NkText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NkImage, {
        src: $props.imageSrc,
        loading: $props.imageLoading,
        alt: $props.imageAlt,
        "object-fit": $props.icon ? 'contain' : 'cover'
      }, null, 8, ["src", "loading", "alt", "object-fit"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NkText, {
        type: "xs",
        content: $props.content
      }, null, 8, ["content"])
    ])
  ], 2))
}