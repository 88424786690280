
import { IonContent, IonPage, IonCol, IonGrid, IonRow } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import NkButton from '@/components/atoms/Button.vue';
import store from '@/store';
export default defineComponent({
  name: 'Testimonials',
  components: {
    NkButton,
    IonContent,
    IonPage,
    IonCol,
    IonGrid,
    IonRow,
  },
  setup() {
    const selectedSpecificationId = ref(null);
    const dialogVisible = ref(false);
    const selectedMediaId = ref(null);
    return {
      selectedSpecificationId,
      dialogVisible,
      selectedMediaId,
      currentSpecification: computed(() => {
        return (
          store.getters['providers/specifications/collection'].find(
            (specification: any) =>
              specification.id === selectedSpecificationId.value,
          ) ||
          store.getters['providers/specifications/collection'][0] ||
          null
        );
      }),
      toggleMedia(mediaId: any) {
        if (selectedMediaId.value === mediaId) selectedMediaId.value = null;
        else selectedMediaId.value = mediaId;
      },
      showDialog() {
        dialogVisible.value = true;
      },
      switchSpecification(specId: any) {
        selectedMediaId.value = null;
        selectedSpecificationId.value = specId;
      },
    };
  },
});
