import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nk-cardContact__image"
}
const _hoisted_2 = { class: "nk-cardContact__body" }
const _hoisted_3 = { class: "nk-cardContact__title" }
const _hoisted_4 = {
  key: 0,
  class: "nk-cardContact__list"
}
const _hoisted_5 = {
  key: 0,
  class: "nk-cardContact__list__item"
}
const _hoisted_6 = { class: "nk-cardContact__list__item__icon" }
const _hoisted_7 = { class: "nk-cardContact__list__item__content" }
const _hoisted_8 = {
  key: 1,
  class: "nk-cardContact__list__item"
}
const _hoisted_9 = { class: "nk-cardContact__list__item__icon" }
const _hoisted_10 = { class: "nk-cardContact__list__item__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Media = _resolveComponent("Media")!
  const _component_NkHeading = _resolveComponent("NkHeading")!
  const _component_NkIcon = _resolveComponent("NkIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    ($props.media)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Media, { media: $props.media }, null, 8, ["media"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NkHeading, {
          type: "t7",
          content: $props.title
        }, null, 8, ["content"])
      ]),
      ($props.tel || $props.email)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            ($props.tel)
              ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_NkIcon, {
                      name: "contactTel",
                      size: "md"
                    })
                  ]),
                  _createElementVNode("span", _hoisted_7, _toDisplayString($props.tel), 1)
                ]))
              : _createCommentVNode("", true),
            ($props.email)
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_NkIcon, {
                      name: "contactEmail",
                      size: "md"
                    })
                  ]),
                  _createElementVNode("span", _hoisted_10, _toDisplayString($props.email), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}