import { argumentsStore } from '@/entities/argument/store';
import { mediasStore as medias } from '@/entities/media/store';
import { usersStore as users } from '@/entities/user/store';
import { userStore as user } from '@/entities/user/store';
import { galleriesStore as galleries } from '@/entities/gallery/store';
import { translationsStore as translations } from '@/entities/translation/store';
import { genesesStore as geneses } from '@/entities/genesis/store';
import { specificationsStore as specifications } from '@/entities/specification/store';
import { testimonialsStore as testimonials } from '@/entities/testimonial/store';
import { awardsStore as awards } from '@/entities/award/store';
import { contactsStore as contacts } from '@/entities/contact/store';
import { defaultContactStore as defaultContact } from '@/entities/defaultContact/store';
import { configuratorsStore as configurators } from '@/entities/configurator/store';
import { faqStore as faq } from '@/entities/faq/store';

export default {
  namespaced: true,
  modules: {
    galleries,
    translations,
    geneses,
    specifications,
    testimonials,
    awards,
    contacts,
    defaultContact,
    configurators,
    medias,
    users,
    user,
    faq,
    arguments: argumentsStore, //arguments is reserved :(
  },
};
