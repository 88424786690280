import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-error-base" }
const _hoisted_2 = { class: "flex justify-center items-center h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_nk_button = _resolveComponent("nk-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_nk_page = _resolveComponent("nk-page")!
  const _component_nk_card_message = _resolveComponent("nk-card-message")!
  const _component_nk_dialog = _resolveComponent("nk-dialog")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_nk_page, {
            title: _ctx.$trans('contact.title'),
            "background-position": "left"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "formEl",
                onSubmit: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"])),
                rules: _ctx.rules,
                model: _ctx.form,
                "label-position": "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_grid, { class: "overflow-hidden" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.entity.label', true),
                                prop: "entity"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_select, {
                                    modelValue: _ctx.form.entity,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.entity = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.entity.placeholder', true)
                    ,
                                    size: "large"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entityOptions, (item) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          key: item.value,
                                          label: item.label,
                                          value: item.label
                                        }, null, 8, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.request.label', true),
                                prop: "request"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_select, {
                                    modelValue: _ctx.form.request,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.request = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.request.placeholder', true)
                    ,
                                    size: "large"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requestOptions, (item) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          key: item.value,
                                          label: item.label,
                                          value: item.label
                                        }, null, 8, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.lastname.label', true),
                                prop: "contact.lastname"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.contact.lastname,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.contact.lastname = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.lastname.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.firstname.label', true),
                                prop: "contact.firstname"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.contact.firstname,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.contact.firstname = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.firstname.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.company.label', true),
                                prop: "contact.company"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.contact.company,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.contact.company = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.company.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.profession.label', true),
                                prop: "contact.profession"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.contact.profession,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.form.contact.profession = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.profession.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.address.label', true),
                                prop: "contact.address"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.contact.address,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.contact.address = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.address.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.zip_code.label', true),
                                prop: "contact.zipCode"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.contact.zipCode,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.form.contact.zipCode = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.zip_code.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.city.label', true),
                                prop: "contact.city"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.contact.city,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.form.contact.city = $event)),
                                    placeholder: _ctx.$trans('contact.form.city.placeholder', true)
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.country.label', true),
                                prop: "contact.country"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.contact.country,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.form.contact.country = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.country.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.phone.label', true),
                                prop: "contact.phone"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    type: "tel",
                                    modelValue: _ctx.form.contact.phone,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.form.contact.phone = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.phone.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.mail.label', true),
                                prop: "contact.email"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    type: "email",
                                    modelValue: _ctx.form.contact.email,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.form.contact.email = $event)),
                                    placeholder: _ctx.$trans('contact.form.mail.placeholder', true)
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$trans('contact.form.comments.label', true),
                                prop: "message"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    type: "textarea",
                                    modelValue: _ctx.form.message,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.form.message = $event)),
                                    placeholder: 
                      _ctx.$trans('contact.form.comments.placeholder', true)
                    
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, { prop: "consent" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_checkbox, {
                                    modelValue: _ctx.form.consent,
                                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.form.consent = $event)),
                                    label: _ctx.$trans('contact.form.consent.label', true),
                                    size: "large"
                                  }, null, 8, ["modelValue", "label"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "-mb-xs" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { class: "flex items-center mb-xs" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, " * " + _toDisplayString(_ctx.$trans('contact.form.requiredFields', true)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, { class: "text-right mb-xs" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_nk_button, {
                                onClick: _ctx.submit,
                                type: "submit",
                                "icon-name": "send"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$trans('contact.form.submit.cta', true)), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["rules", "model"])
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_nk_dialog, {
            modelValue: _ctx.dialogVisible,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.dialogVisible = $event)),
            "hide-close": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_nk_card_message, {
                  title: _ctx.formNotification.message,
                  type: _ctx.formNotification.type,
                  class: "w-[730px] break-normal",
                  onClose: _cache[15] || (_cache[15] = ($event: any) => (_ctx.dialogVisible = false))
                }, null, 8, ["title", "type"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}