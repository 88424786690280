
import { reactive, computed } from 'vue';
import { IonImg } from '@ionic/vue';

const __default__ = {
  components: {
    IonImg,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    loading: {
      type: String,
      default: 'eager',
    },
    alt: {
      type: String,
      required: true,
    },
    objectFit: {
      type: String,
      default: 'cover',
    },
    objectPosition: {
      type: String,
      default: '50% 50%',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-image': true,
        'nk-image--rounded': props.rounded,
        'nk-image--shadow': props.shadow,
      })),
      style: computed(() => ({
        objectFit: props.objectFit,
        objectPosition: props.objectPosition,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "405a3842": (_ctx.$theme.borderRadius.sm)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__