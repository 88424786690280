import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkLocaleSwitch = _resolveComponent("NkLocaleSwitch")!

  return ($setup.locales.length > 1)
    ? (_openBlock(), _createBlock(_component_NkLocaleSwitch, {
        key: 0,
        "current-locale": $setup.currentLocale,
        locales: $setup.locales,
        onLocaleChange: $options.setLocale
      }, null, 8, ["current-locale", "locales", "onLocaleChange"]))
    : _createCommentVNode("", true)
}