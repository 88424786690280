
import { computed } from 'vue';
import store from '@/store';
import NkLocaleSwitch from '@/components/molecules/LocaleSwitch';

export default {
  components: {
    NkLocaleSwitch,
  },
  setup(): any {
    return {
      currentLocale: computed(() => {
        return store.getters['i18n/locale'];
      }),
      locales: computed(() => {
        return store.getters['i18n/locales'];
      }),
    };
  },
  methods: {
    setLocale($event: string): void {
      store.dispatch('i18n/setLocale', $event);
    },
  },
};
