import { createProviderStoreInstance } from '@/modules/provider-manager';
import { faqProvider } from '@/entities/faq/providers';

const providerStore = createProviderStoreInstance(faqProvider);

export const faqStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
  },
  getters: {
    ...providerStore.getters,
    pdfUrl(state: any, getters: any, rootState: any, rootGetters: any) {
      const faqDoc = getters['collection'][0];
      if (getters['collection'][0]) {
        const mediaId = rootGetters['i18n/trans'](faqDoc.media);
        const media = rootGetters['providers/medias/getItem'](mediaId);
        return media?.url || '';
      }
      return '';
    },
  },
  mutations: {
    ...providerStore.mutations,
  },
  actions: {
    ...providerStore.actions,
  },
};
