import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkOverlayScrollbars = _resolveComponent("NkOverlayScrollbars")!

  return (_openBlock(), _createBlock(_component_NkOverlayScrollbars, {
    class: "h-auto w-full",
    options: { scrollbars: { visibility: 'hidden' } }
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", {
        class: _normalizeClass(["nk-navigationList", 
        $props.isGrid
          ? 'grid grid-cols-2 xl3:grid-cols-4 items-stretch gap-y-lg'
          : 'flex'
      ])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nk-navigationListItem",
              key: link.id
            }, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          }), 128))
        ])
      ], 2)
    ]),
    _: 3
  }))
}