import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!

  return (_openBlock(), _createBlock(_component_ion_img, {
    draggable: "false",
    class: _normalizeClass($setup.classes),
    src: $props.src,
    loading: $props.loading,
    alt: $props.alt,
    style: _normalizeStyle($setup.style)
  }, null, 8, ["class", "src", "loading", "alt", "style"]))
}