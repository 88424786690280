import { createProviderStoreInstance } from '@/modules/provider-manager';
import { configuratorsProvider } from '@/entities/configurator/providers';
import { flatten, orderBy, pull, pullAll, uniq } from 'lodash';
const providerStore = createProviderStoreInstance(configuratorsProvider);

export const configuratorsStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
    openedCategories: [],
    selectedOptions: [],
    selectedColor: null,
    selectedVehicle: null,
  },
  getters: {
    ...providerStore.getters,
    openedCategories(state: any) {
      return state.openedCategories;
    },
    selectedOptions(state: any) {
      return state.selectedOptions;
    },
    selectedVehicle(state: any) {
      return state.selectedVehicle;
    },
    currentColor(state: any, getters: any) {
      return state.selectedColor || getters['colors'][0];
    },
    item(state: any) {
      return state.collection[0] || null;
    },
    categories(state: any, getters: any) {
      return orderBy(
        getters['item']?.categories.filter(
          (category: any) => category.isOnline,
        ) || [],
        ['position'],
        ['asc'],
      );
    },
    faces(state: any, getters: any) {
      return orderBy(
        getters['item']?.faces.filter((face: any) => face.isOnline) || [],
        ['position'],
        ['asc'],
      );
    },
    colors(state: any, getters: any) {
      return orderBy(
        getters['item']?.colors.filter((color: any) => color.isOnline) || [],
        ['position'],
        ['asc'],
      );
    },
    getRecursiveOptions: (state: any, getters: any) => (
      options: any,
      accumulatedOptions: any[] = [],
    ) => {
      options
        .filter((option: any) => {
          if (
            (state.selectedVehicle === 'MAGA' && option.isMaga) ||
            (state.selectedVehicle === 'N1' && option.isN1) ||
            state.selectedVehicle == null
          ) {
            return option;
          }
        })
        .map((option: any) => {
          accumulatedOptions.push(option);
          if (option.children) {
            getters['getRecursiveOptions'](option.children, accumulatedOptions);
          }
        });
      return accumulatedOptions;
    },
    getOptionsByIds: (state: any, getters: any) => (ids: any[]) => {
      return getters['allOptions'].filter((option: any) =>
        ids.includes(option.id),
      );
    },
    getOptionsByVehicle: (state: any, getters: any) => (options: any[]) => {
      const opts = options.filter((option: any) => {
        if (
          ((state.selectedVehicle === 'MAGA' && option.isMaga) ||
            (state.selectedVehicle === 'N1' && option.isN1) ||
            state.selectedVehicle == null) &&
          option.isOnline
        ) {
          return option;
        }
      });
      return opts;
    },
    allExceptions(state: any, getters: any) {
      const options = getters['getOptionsByIds'](state.selectedOptions);
      const exceptions = uniq(
        flatten(options.map((option: any) => option.exceptions)),
      );
      const exceptionObjects = getters['getOptionsByIds'](exceptions);
      return getters['getRecursiveOptions'](exceptionObjects);
    },
    allOptions(state: any, getters: any) {
      const arrayOfOptions = getters['categories'].map((category: any) => {
        return getters['getRecursiveOptions'](category.options);
      });
      return orderBy(flatten(arrayOfOptions), ['position'], ['asc']);
    },
    currentLayers(state: any, getters: any) {
      return getters['allOptions'].filter((option: any) =>
        state.selectedOptions.includes(option.id),
      );
    },
  },
  mutations: {
    ...providerStore.mutations,
    toggleOpenCategory(state: any, value: number) {
      if (state.openedCategories.includes(value)) {
        pull(state.openedCategories, value);
      } else {
        state.openedCategories.push(value);
      }
    },
    removeOptions(state: any, value: any[]) {
      pullAll(state.selectedOptions, value);
    },
    addOption(state: any, value: any) {
      state.selectedOptions.push(value);
    },
    resetConfiguration(state: any) {
      state.openedCategories = [];
      state.selectedOptions = [];
      state.selectedColor = null;
      state.selectedVehicle = null;
    },
    setColor(state: any, value: any) {
      state.selectedColor = value;
    },
    setVehicle(state: any, value: any) {
      state.openedCategories = [];
      state.selectedOptions = [];
      state.selectedVehicle = value;
    },
  },
  actions: {
    ...providerStore.actions,
    toggleOpenCategory({ commit }: any, categoryId: number) {
      commit('toggleOpenCategory', categoryId);
    },
    toggleOption({ commit, state, getters }: any, option: any) {
      if (state.selectedOptions.includes(option.id)) {
        const options = getters['getRecursiveOptions']([option]);
        const idsToPull = options.map((opt: any) => opt.id);
        commit('removeOptions', idsToPull);
      } else {
        commit('addOption', option.id);
        const exceptions = getters['getOptionsByIds'](option.exceptions);
        const recursiveExceptions = getters['getRecursiveOptions'](exceptions);
        commit(
          'removeOptions',
          recursiveExceptions.map((exception: any) => exception.id),
        );
      }
    },
    resetConfiguration({ commit }: any) {
      commit('resetConfiguration');
    },
    setColor({ commit }: any, color: any) {
      commit('setColor', color);
    },
    setVehicle({ commit }: any, name: any) {
      commit('setVehicle', name);
    },
  },
};
