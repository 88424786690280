
import { ref, computed, onMounted } from 'vue';
import Dialog from '@/components/templates/Dialog.vue';
import ConfiguratorOption from '@/components/molecules/ConfiguratorOption.vue';
import store from '@/store';
import router from '@/router';
import { Electron } from '@/modules/electron';
import { App } from '@capacitor/app';
export default {
  components: {
    Dialog,
    ConfiguratorOption,
  },
  setup(): any {
    let appInfo: any = ref(null);

    onMounted(async () => {
      appInfo.value = await getAppInfo();
    });
    const open = computed({
      get: () => store.state.settingsOpen,
      set: (value) => store.dispatch('setSettingsOpenState', value),
    });
    const adminUrl = process.env.VUE_APP_ADMIN_BASE_URL;

    const getAppInfo = async () => {
      if (store.state.app.deviceInfo?.platform === 'electron') {
        return { version: await Electron.getVersion(), build: null };
      }
      return await App.getInfo().then((data) => data);
    };

    return {
      adminUrl,
      appInfo,
      getAppInfo,
      openAdmin() {
        if (store.state.app.deviceInfo?.platform === 'electron') {
          Electron.exec({
            path: 'shell.openExternal',
            args: [adminUrl],
          });
        } else {
          (window as any).open(adminUrl, '_blank').focus();
        }
      },
      openFAQ() {
        const faqUrl = store.getters['providers/faq/pdfUrl'];
        if (!faqUrl) return;
        if (store.state.app.deviceInfo?.platform === 'electron') {
          Electron.exec({
            path: 'shell.openExternal',
            args: [faqUrl],
          });
        } else {
          (window as any).open(faqUrl, '_blank').focus();
        }
      },
      logout() {
        store
          .dispatch('providers/user/deleteAllDocs')
          .then(() => setTimeout(() => router.push({ name: 'Login' }), 1000));
        open.value = false;
      },
      close() {
        return window.close();
      },
      open,
      currentUser: computed(() => {
        return store.getters['providers/user/collection'][0];
      }),
      settings: ref({
        offlineMode: true,
      }),
    };
  },
};
