import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import Arguments from '@/views/Arguments.vue';
import Gallery from '@/views/Gallery.vue';
import Specifications from '@/views/Specifications.vue';
import Genesis from '@/views/Genesis.vue';
import Testimonials from '@/views/Testimonials.vue';
import Contact from '@/views/Contact.vue';
import ContactWeb from '@/views/ContactWeb.vue';
import Login from '@/views/Login.vue';
import Configurator from '@/views/Configurator.vue';
import store from '@/store';

export let isLoggedIn = false;

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/arguments',
    name: 'Argument',
    component: Arguments,
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery,
  },
  {
    path: '/specifications',
    name: 'Specification',
    component: Specifications,
  },
  {
    path: '/genesis',
    name: 'Genesis',
    component: Genesis,
  },
  {
    path: '/testimonials',
    name: 'Testimony',
    component: Testimonials,
  },
  {
    path: '/contact',
    name: 'ContactWeb',
    component: ContactWeb,
  },
  {
    path: '/contact-pro',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/configurator',
    name: 'Configurator',
    component: Configurator,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const users = await store.dispatch('providers/user/fetch');
  const hasUser = users[0];
  isLoggedIn = hasUser ? true : false;
  try {
    return next();
  } catch (e) {
    next({ name: 'Home' });
  }
});
router.afterEach(async () => {
  const users = await store.dispatch('providers/user/fetch');
  const hasUser = users[0];
  isLoggedIn = hasUser ? true : false;
});
export default router;
