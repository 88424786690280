
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import NkIcon from '@/components/atoms/Icon';

const __default__ = {
  components: {
    NkIcon,
  },

  props: {
    currentLocale: {
      type: String,
      required: true,
    },
    locales: {
      type: Array,
      required: true,
    },
  },

  setup(): any {
    const target = ref(null);
    const open = ref(false);
    onClickOutside(target, () => (open.value = false));
    return {
      target,
      open,
      classes: computed(() => ({
        'nk-localeSwitch': true,
        'nk-localeSwitch--open': open.value,
      })),
    };
  },

  computed: {
    availableLocales(): [] {
      return (this as any).locales.filter(
        (item: any) => item !== (this as any).currentLocale,
      );
    },
  },

  methods: {
    setLocale(locale: string): void {
      (this as any).$emit('localeChange', locale);
      (this as any).open = false;
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3059ebbf": (_ctx.$theme.spacing.xs),
  "3059eb1e": (_ctx.$theme.spacing.sm),
  "4a38e75a": (_ctx.$theme.spacing.xs2),
  "63f21946": (_ctx.$theme.borderRadius.full),
  "16e6f15c": (_ctx.$theme.fontFamily.sans1),
  "313cfc99": (_ctx.$theme.fontSize.xl),
  "0dd335c6": (_ctx.$theme.lineHeight.xs2),
  "f81b503c": (_ctx.$theme.colors.primary.base),
  "7e32cb05": (_ctx.$theme.transitions.fast),
  "466e460e": (_ctx.$theme.borderRadius.md),
  "9978d57e": (_ctx.$theme.colors.primary.lightest)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__