
import { reactive, computed } from 'vue';

const __default__ = {
  props: {
    content: {
      type: String,
    },
    type: {
      type: String,
      validator: function (value: string): boolean {
        return ['xs2', 'xs', 'sm'].indexOf(value) !== -1;
      },
    },
    inheritColor: {
      type: Boolean,
      default: false,
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-text': true,
        [`nk-text--${props.type}`]: props.type,
        'nk-text--inheritColor': props.inheritColor,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "76c07940": (_ctx.$theme.fontFamily.sans2),
  "1fa9881d": (_ctx.$theme.fontSize.xl3),
  "72fb0c11": (_ctx.$theme.lineHeight.sm),
  "171da46d": (_ctx.$theme.colors.secondary.base),
  "581761b7": (_ctx.$theme.spacing.xl2),
  "a31e5d46": (_ctx.$theme.fontSize.lg),
  "bf3f1a70": (_ctx.$theme.lineHeight.base),
  "55054b9a": (_ctx.$theme.fontSize.base),
  "72fb0b4e": (_ctx.$theme.lineHeight.md),
  "a31e5a46": (_ctx.$theme.fontSize.xs),
  "72fb0b32": (_ctx.$theme.lineHeight.lg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__