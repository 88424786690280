import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02f8cf36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page__back" }
const _hoisted_2 = { class: "page__flow" }
const _hoisted_3 = {
  key: 0,
  class: "mb-md px-md"
}
const _hoisted_4 = { class: "relative flex-grow" }
const _hoisted_5 = { class: "absolute inset-0" }
const _hoisted_6 = {
  key: 0,
  class: "text-center mb-xl"
}
const _hoisted_7 = {
  key: 1,
  class: "text-center mt-xl2 mb-xl"
}
const _hoisted_8 = {
  key: 1,
  class: "page__tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackToHomeButton = _resolveComponent("BackToHomeButton")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_NkHeading = _resolveComponent("NkHeading")!
  const _component_NkOverlayScrollbars = _resolveComponent("NkOverlayScrollbars")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    ($props.navigation)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_BackToHomeButton)
          ]),
          _createVNode(_component_Navigation)
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_grid, { class: "h-full w-full" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "h-full" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                size: _ctx.$slots.aside ? '6' : '12',
                class: "flex flex-col"
              }, {
                default: _withCtx(() => [
                  ($props.title)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_NkHeading, {
                          type: "t2",
                          content: $props.title
                        }, null, 8, ["content"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      ($props.hasOverlay)
                        ? (_openBlock(), _createBlock(_component_NkOverlayScrollbars, { key: 0 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass({ 'p-md': $props.hasPadding })
                              }, [
                                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                              ], 2)
                            ]),
                            _: 3
                          }))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["h-full", { 'p-md': $props.hasPadding }])
                          }, [
                            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                          ], 2))
                    ])
                  ])
                ]),
                _: 3
              }, 8, ["size"]),
              (_ctx.$slots.aside || _ctx.$slots.asideBis)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 0,
                    push: "1",
                    size: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NkOverlayScrollbars, null, {
                        default: _withCtx(() => [
                          ($props.asideTitle)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_NkHeading, {
                                  type: "t2",
                                  content: $props.asideTitle
                                }, null, 8, ["content"])
                              ]))
                            : _createCommentVNode("", true),
                          _renderSlot(_ctx.$slots, "aside", {}, undefined, true),
                          ($props.asideBisTitle)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode(_component_NkHeading, {
                                  type: "t2",
                                  content: $props.asideBisTitle
                                }, null, 8, ["content"])
                              ]))
                            : _createCommentVNode("", true),
                          _renderSlot(_ctx.$slots, "asideBis", {}, undefined, true)
                        ]),
                        _: 3
                      })
                    ]),
                    _: 3
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    (_ctx.$slots.tabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "tabs", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}