
import { props } from 'bluebird';
import Media from './Media.vue';

const __default__ = {
  inheritAttrs: false,
  components: {
    Media,
  },
  props: {
    media: {
      required: true,
    },
    fit: {
      type: String,
      default: 'cover',
    },
    position: {
      type: String,
      default: '50% 50%',
    },
  },
  provide(): any {
    return {
      cache: (this as any).cache, // objects created in data() are reactive
    };
  },
  data() {
    return {
      isVisible: false,
      entry: null,
      cache: {
        localMedia: null,
        src: null,
      },
    };
  },
  computed: {
    computedMedia() {
      const { $store, media } = this as any;
      if (media && ['string', 'number'].includes(typeof media)) {
        return ((this as any).reactiveMedia = $store.getters[
          'providers/medias/getItem'
        ](media));
      }
      return ((this as any).reactiveMedia = media);
    },
  },
  methods: {
    visibilityChanged(isVisible: any, entry: any) {
      (this as any).isVisible = isVisible;
      (this as any).entry = entry;
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "355dce47": (_ctx.fit),
  "26956e9a": (_ctx.position)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__