
import NkOverlayScrollbars from '@/components/atoms/OverlayScrollbars';

const __default__ = {
  components: {
    NkOverlayScrollbars,
  },
  props: {
    isGrid: {
      default: false,
      type: Boolean,
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "6b0cdcbe": (_ctx.$theme.spacing.lg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__