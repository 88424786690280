import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nk-templateHome__localeSwitch" }
const _hoisted_2 = { class: "nk-templateHome__nav" }
const _hoisted_3 = {
  class: "nk-templateHome__tabs",
  ref: "tabs"
}
const _hoisted_4 = { class: "nk-templateHome__flow" }
const _hoisted_5 = {
  class: "nk-templateHome__logo",
  ref: "logo"
}
const _hoisted_6 = { class: "nk-templateHome__title" }
const _hoisted_7 = { class: "nk-templateHome__title__logo1" }
const _hoisted_8 = { class: "nk-templateHome__title__logo2" }
const _hoisted_9 = { class: "nk-templateHome__content" }
const _hoisted_10 = { class: "nk-templateHome__content__slider" }
const _hoisted_11 = {
  class: "nk-templateHome__content__slider__thumb",
  ref: "sliderThumb"
}
const _hoisted_12 = {
  class: "nk-templateHome__content__slider__cursor",
  ref: "sliderCursor"
}
const _hoisted_13 = {
  class: "nk-templateHome__content__slider__cursor__left",
  ref: "sliderCursorLeft"
}
const _hoisted_14 = {
  class: "nk-templateHome__content__slider__cursor__right",
  ref: "sliderCursorRight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocaleSwitch = _resolveComponent("LocaleSwitch")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_NkImage = _resolveComponent("NkImage")!
  const _component_NkHeading = _resolveComponent("NkHeading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _createElementVNode("div", {
      class: "nk-templateHome__background",
      style: _normalizeStyle({ backgroundImage: `url(${$props.backgroundImageSrc})` })
    }, null, 4),
    _createElementVNode("div", {
      class: "nk-templateHome__foreground",
      style: _normalizeStyle({ backgroundImage: `url(${$props.foregroundImageSrc})` }),
      ref: "foreground"
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LocaleSwitch)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Navigation, {
        rollable: false,
        open: true
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "tabs")
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_NkImage, {
          src: $props.mainLogoSrc,
          alt: $props.mainLogoAlt,
          "object-fit": "contain"
        }, null, 8, ["src", "alt"])
      ], 512),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_NkHeading, {
          tag: "h1",
          type: "t4",
          class: "pt-xs2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString($props.title), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_NkImage, {
            src: $props.titleLogo1Src,
            alt: $props.titleLogo1Alt,
            "object-fit": "contain"
          }, null, 8, ["src", "alt"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_NkImage, {
            src: $props.titleLogo2Src,
            alt: $props.titleLogo2Alt,
            "object-fit": "contain"
          }, null, 8, ["src", "alt"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("input", {
            type: "range",
            min: "0",
            max: "100",
            value: "50",
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.updateSlider && $options.updateSlider(...args))),
            onWheel: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, null, 32),
          _createElementVNode("div", _hoisted_11, null, 512),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, null, 512),
            _createElementVNode("div", _hoisted_14, null, 512)
          ], 512)
        ])
      ])
    ])
  ], 2))
}