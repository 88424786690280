export const i18nStorePlugin = (store: any) => {
  store.registerModule('i18n', {
    namespaced: true,
    state: {
      defaultLocale: 'fr',
      locale: 'fr',
      locales: ['fr', 'en', 'de', 'nl', 'pt', 'es'],
    },
    getters: {
      locale: (state: any) => state.locale,
      defaultLocale: (state: any) => state.defaultLocale,
      locales: (state: any) => state.locales,
      trans: (state: any, getters: any, rootState: any, rootGetters: any) => (
        translatable: any,
      ) => {
        if (!translatable || Array.isArray(translatable)) return '';
        if (typeof translatable === 'string') {
          const translation = rootGetters['providers/translations/getByName'](
            translatable,
          );
          if (translation) translatable = translation.translations;
        }
        return translatable[state.locale]
          ? translatable[state.locale]
          : translatable[state.defaultLocale]
          ? translatable[state.defaultLocale]
          : typeof translatable === 'string'
          ? translatable
          : null;
      },
    },
    mutations: {
      setLocale(state: any, value: string) {
        state.locale = value;
      },
    },
    actions: {
      setLocale({ commit }: any, value: string) {
        commit('setLocale', value);
      },
    },
  });
};
