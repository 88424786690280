import store from '@/store';
import '@/services/interceptors/jwt';
import { isLoggedIn } from 'axios-jwt';
import { apiInstance } from '@/services/api';
import { login } from '@/services/auth';

apiInstance.interceptors.request.use(async (config) => {
  await store.dispatch('app/updateNetworkStatus');
  return config;
});

let loggingIn: any = null;
apiInstance.interceptors.request.use(async (config) => {
  // check if refresh token exists
  if (isLoggedIn()) {
    // assume we are logged in because we have a refresh token
    return config;
  }
  // because of concurrency loging check can be called several times so this allows to call it once
  if (loggingIn) {
    await loggingIn;
    return config;
  }

  loggingIn = login();
  await loggingIn;
  loggingIn = null;
  return config;
});

export { apiInstance as api } from '@/services/api';
