import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nk-cardImage__image absolute inset-0" }
const _hoisted_2 = {
  key: 0,
  class: "nk-cardImage__image__cta"
}
const _hoisted_3 = { class: "nk-cardImage__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkMedia = _resolveComponent("NkMedia")!
  const _component_NkIcon = _resolveComponent("NkIcon")!
  const _component_NkHeading = _resolveComponent("NkHeading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative", {
        'aspect-w-4 aspect-h-3': $props.thumbnail,
        'flex-grow': !$props.thumbnail,
      }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NkMedia, {
          controls: $props.controls,
          autoplay: $props.autoplay,
          media: $props.media,
          fit: $props.thumbnail ? 'cover' : 'contain',
          class: "w-full h-full"
        }, null, 8, ["controls", "autoplay", "media", "fit"]),
        ($props.video)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_NkIcon, { name: "play" })
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (!$props.big)
        ? (_openBlock(), _createBlock(_component_NkHeading, {
            key: 0,
            type: "t5"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.title), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_NkHeading, {
            key: 1,
            type: "t4",
            content: $props.title,
            class: "text-center"
          }, null, 8, ["content"]))
    ])
  ], 2))
}