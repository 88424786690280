
import { computed, reactive } from 'vue';
import BackToHomeButton from '@/entities/app/components/actions/BackToHomeButton.vue';
import NkHeading from '@/components/atoms/Heading.vue';
import NkOverlayScrollbars from '@/components/atoms/OverlayScrollbars';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import Navigation from '@/entities/app/components/actions/Navigation.vue';

const __default__ = {
  components: {
    BackToHomeButton,
    NkHeading,
    NkOverlayScrollbars,
    IonGrid,
    IonRow,
    IonCol,
    Navigation,
  },
  props: {
    navigation: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    asideTitle: {
      type: String,
    },
    asideBisTitle: {
      type: String,
    },
    backgroundPosition: {
      type: String,
      default: 'right',
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
    hasOverlay: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        page: true,
        'page--backgroundLeft': props.backgroundPosition === 'left',
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "682675b4": (_ctx.$theme.borderRadius.full),
  "6a744dbe": (_ctx.$theme.colors.primary.lightest),
  "6076c14c": (_ctx.$theme.spacing.xl2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__