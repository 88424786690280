import axios from 'axios';
import { login, logout } from '@/services/auth';
import { getAccessToken } from 'axios-jwt';
export const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config.__isRetry
    ) {
      const config = { ...error.config };
      await logout();
      await login();
      config.__isRetry = true;
      config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
      return axios.request(config);
    }
    return Promise.reject(error);
  },
);
