
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent, ref, computed, watchEffect } from 'vue';
import store from '@/store';
import { useRoute } from 'vue-router';
export default defineComponent({
  name: 'Arguments',
  components: {
    IonContent,
    IonPage,
  },
  setup() {
    const route = useRoute();
    const currentBenefit = ref(null);
    const lastNavigatedArgumentId = ref(null);
    const currentVideo = ref(null);
    const videoPlaying = ref(true);
    const dialogVisible = ref(false);
    const mutationObserver = null;
    watchEffect(() => {
      if (route.name === 'Argument' && route.query.id) {
        currentBenefit.value = null;
        (lastNavigatedArgumentId.value as any) = Number(route.query.id);
      }
    });
    return {
      currentBenefit,
      lastNavigatedArgumentId,
      currentVideo,
      videoPlaying,
      dialogVisible,
      mutationObserver,
      selectedArgumentId: computed(() => {
        return lastNavigatedArgumentId.value || Number(route.query.id);
      }),
    };
  },
  computed: {
    currentArgument() {
      return (
        store.getters['providers/arguments/collection'].find(
          (argument: any) => argument.id === (this as any).selectedArgumentId,
        ) ||
        store.getters['providers/arguments/collection'][0] ||
        null
      );
    },
    currentArgumentBenefits() {
      return (this as any).currentArgument.argumentBenefits.filter(
        (benefit: any) => benefit.isOnline,
      );
    },
    currentMedia(): any {
      const media = (this as any).currentBenefit?.media;
      const translatedMedia = (this as any).$trans(media);
      if (translatedMedia) {
        return (this as any).$store.getters['providers/medias/getItem'](
          translatedMedia,
        );
      }
      return (this as any).$store.getters['providers/medias/getItem'](
        (this as any).$trans((this as any).currentArgument.backgroundMedia),
      );
    },
    currentMediaDetail(): any {
      const mediaDetail = (this as any).currentBenefit?.mediaDetail;
      const translatedMediaDetail = (this as any).$trans(mediaDetail);
      if (translatedMediaDetail) {
        return (this as any).$store.getters['providers/medias/getItem'](
          translatedMediaDetail,
        );
      }
      return null;
    },
  },
  mounted(): void {
    let videoCache: any = null;
    (this as any).mutationObserver = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        (this as any).$nextTick(() => {
          (this as any).currentVideo = (mutation as any).target.querySelector(
            'video',
          );
          if (
            (this as any).currentVideo &&
            (this as any).currentVideo !== videoCache
          ) {
            videoCache = (this as any).currentVideo;
            (this as any).setVideoPlaying();
            (this as any).currentVideo.addEventListener(
              'play',
              (this as any).setVideoPlaying,
            );
            (this as any).currentVideo.addEventListener(
              'pause',
              (this as any).setVideoPlaying,
            );
          }
        });
      }
    });
    (this as any).mutationObserver.observe((this as any).$refs.root, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  },
  beforeUnmount(): void {
    (this as any).mutationObserver.disconnect();
  },
  methods: {
    switchVideoPlayback(): void {
      (this as any).currentVideo = (this as any).$refs.media.querySelector(
        'video',
      );
      if ((this as any).videoPlaying) (this as any).currentVideo.pause();
      else (this as any).currentVideo.play();
    },
    setVideoPlaying(): void {
      if ((this as any).currentVideo)
        (this as any).videoPlaying = !(this as any).currentVideo.paused;
      else (this as any).videoPlaying = true;
    },
  },
});
