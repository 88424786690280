import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nk-pageArgument__back" }
const _hoisted_2 = {
  key: 0,
  class: "nk-pageArgument__main"
}
const _hoisted_3 = { class: "nk-pageArgument__main__wrapper" }
const _hoisted_4 = { class: "p-md" }
const _hoisted_5 = {
  class: "nk-pageArgument__tabs",
  ref: "tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackToHomeButton = _resolveComponent("BackToHomeButton")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_NkOverlayScrollbars = _resolveComponent("NkOverlayScrollbars")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BackToHomeButton)
    ]),
    _createVNode(_component_Navigation),
    _createElementVNode("div", {
      class: _normalizeClass(["nk-pageArgument__aside", { 'nk-pageArgument__aside--full': $props.asideFull }])
    }, [
      _createVNode(_TransitionGroup, {
        "enter-from-class": "opacity-0",
        "enter-active-class": "transition-opacity duration-500",
        "leave-to-class": "opacity-0",
        "leave-active-class": "transition-opacity duration-500"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "aside")
        ]),
        _: 3
      })
    ], 2),
    (!$props.asideFull)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_NkOverlayScrollbars, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "main")
                ])
              ]),
              _: 3
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "tabs")
    ], 512)
  ], 2))
}