
import NkHeading from '@/components/atoms/Heading';
import NkText from '@/components/atoms/Text';
import NkConfiguratorOptionExceptions from '@/components/atoms/ConfiguratorOptionExceptions.vue';

const __default__ = {
  name: 'NkConfiguratorOption',
  components: {
    NkHeading,
    NkText,
    NkConfiguratorOptionExceptions,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    exceptions: {
      type: Array,
      default: (): [] => [],
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, context: any): any {
    return {
      toggle() {
        context.emit('toggle');
      },
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "54858c2d": (_ctx.$theme.colors.grey.light),
  "a4d487dc": (_ctx.$theme.borderRadius.sm),
  "14bc707a": (_ctx.$theme.spacing.md),
  "14bc71d7": (_ctx.$theme.spacing.xl),
  "fa5c6d8a": (_ctx.$theme.spacing.xl2),
  "14bc713d": (_ctx.$theme.spacing.sm),
  "20f38cfc": (_ctx.$theme.colors.primary.lightest),
  "73eb44a3": (_ctx.$theme.colors.primary.base),
  "5dae1e4c": (_ctx.$theme.colors.grey.base)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__