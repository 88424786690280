import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Media = _resolveComponent("Media")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["media", [_ctx.$attrs.class]]),
    style: _normalizeStyle([_ctx.$attrs.style])
  }, [
    ($data.isVisible && $options.computedMedia)
      ? (_openBlock(), _createBlock(_component_Media, _mergeProps({ key: 0 }, { ..._ctx.$attrs, class: '', style: '' }, { media: $options.computedMedia }), null, 16, ["media"]))
      : _createCommentVNode("", true)
  ], 6)), [
    [_directive_observe_visibility, $options.visibilityChanged]
  ])
}