
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import store from '@/store';
export default defineComponent({
  name: 'Testimony',
  components: {
    IonContent,
    IonPage,
  },
  setup() {
    const selectedTestimonyId = ref(null);
    return {
      selectedTestimonyId,
      currentTestimony: computed(() => {
        return (
          store.getters['providers/testimonials/collection'].find(
            (testimony: any) => testimony.id === selectedTestimonyId.value,
          ) ||
          store.getters['providers/testimonials/collection'].find(
            (testimony: any) => testimony.isHighlight,
          ) ||
          store.getters['providers/testimonials/collection'][0] ||
          null
        );
      }),
    };
  },
});
