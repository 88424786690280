import PouchDB from 'pouchdb';
import { Promise } from 'bluebird';
import { v4 as uuid } from 'uuid';
import store from '@/store';
import { api } from '@/services';
import providerManager from './ProviderManager';

export class Provider {
  readonly db: any;
  public data: any[] = [];

  constructor(public name: string, public params?: App.ProviderParams) {
    this.db = new PouchDB(name, { revs_limit: 1, auto_compaction: true });
    providerManager.add(this);
  }
  async deleteDocs(data: any | any[]) {
    if (!Array.isArray(data)) data = [data];
    return Promise.map(data, (entry: any) => {
      return this.db.remove(entry);
    }).then(() => {
      return this.readAllDocs();
    });
  }
  async setData(data: any | any[]) {
    if (!Array.isArray(data)) data = [data];
    return Promise.map(data, (entry: any) => {
      const id = entry.id ? entry.id.toString() : uuid();
      return this.db.upsert(id, () => ({
        ...entry,
        _id: id,
      }));
    }).then(() => {
      return this.readAllDocs();
    });
  }

  getData() {
    return this.data;
  }

  getDB() {
    return this.db;
  }

  async readAllDocs() {
    return this.db.allDocs({ include_docs: true }).then(async (docs: any) => {
      this.data = docs.rows.map((item: any) => item.doc);
      return this.data;
    });
  }

  public async fetchDB() {
    if (this.params && this.params.url && store.getters['app/isOnline']) {
      return api({
        url: this.params.url,
      }).then(async (res) => {
        await this.setData(res.data);
        return res.data;
      });
    }
    return this.readAllDocs();
  }
}
