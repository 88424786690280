import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "swiper-wrapper flex-1" }
const _hoisted_2 = { class: "nk-slider__controls" }
const _hoisted_3 = {
  ref: "slider-prev",
  class: "nk-slider__navigationItem nk-slider__navigationItem--prev"
}
const _hoisted_4 = {
  ref: "slider-pagination",
  class: "nk-slider__pagination"
}
const _hoisted_5 = {
  ref: "slider-next",
  class: "nk-slider__navigationItem nk-slider__navigationItem--next"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkIcon = _resolveComponent("NkIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["swiper-container h-full flex flex-col", { 'p-md': $props.hasPadding }]),
      ref: "swiper"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_NkIcon, {
            name: "arrow",
            class: "transform rotate-180"
          })
        ], 512),
        _createElementVNode("div", _hoisted_4, null, 512),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_NkIcon, { name: "arrow" })
        ], 512)
      ])
    ], 2)
  ], 2))
}