
import { ref } from 'vue';
import { merge } from 'lodash';
import OverlayScrollbars from 'overlayscrollbars';
import 'overlayscrollbars/css/OverlayScrollbars.css';
const __default__ = {
  props: {
    options: {
      type: Object,
      default: (): any => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(): any {
    const element = ref(null);
    let instance = ref(null);
    const defaultOptions = ref(null);
    return {
      element,
      instance,
      defaultOptions,
    };
  },
  mounted(): void {
    (this as any).defaultOptions = {};
    if (!(this as any).disabled) {
      (this as any).instance = OverlayScrollbars(
        (this as any).element,
        merge((this as any).defaultOptions, (this as any).options),
      );
    }
  },
  beforeUnmount(): void {
    (this as any).instance?.destroy();
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1977a4e7": (_ctx.$theme.colors.primary.base)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__