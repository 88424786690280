
import { reactive, computed } from 'vue';
import NkImage from '@/components/atoms/Image';
import NkText from '@/components/atoms/Text';

const __default__ = {
  components: {
    NkImage,
    NkText,
  },

  props: {
    content: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    imageLoading: {
      type: String,
      default: 'eager',
    },
    imageAlt: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-tile': true,
        'nk-tile--icon': props.icon,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5cdb1c94": (_ctx.$theme.borderRadius.sm),
  "7e2bf804": (_ctx.$theme.colors.primary.lightest),
  "4dd9469c": (_ctx.$theme.spacing.lg),
  "4dd946b8": (_ctx.$theme.spacing.md)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__