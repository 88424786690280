
import { reactive, computed } from 'vue';
import NkHeading from '@/components/atoms/Heading';
import NkIcon from '@/components/atoms/Icon';
import NkMedia from '@/components/atoms/Media';

const __default__ = {
  components: {
    NkHeading,
    NkIcon,
    NkMedia,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    media: {
      type: String,
      required: true,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-cardImage': true,
        'nk-cardImage--video': props.video,
        'nk-cardImage--big': props.big,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "cd1a786a": (_ctx.$theme.spacing.xs),
  "eca19e96": (_ctx.$theme.colors.primary.lightest),
  "72146a11": (_ctx.$theme.transitions.fast),
  "40bdcd56": (_ctx.$theme.colors.primary.base),
  "cd1a7878": (_ctx.$theme.spacing.xl)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__