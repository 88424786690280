import { createProviderStoreInstance } from '@/modules/provider-manager';
import { defaultContactProvider } from '@/entities/defaultContact/providers';

const providerStore = createProviderStoreInstance(defaultContactProvider);

export const defaultContactStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
  },
  getters: {
    ...providerStore.getters,
  },
  mutations: {
    ...providerStore.mutations,
  },
  actions: {
    ...providerStore.actions,
  },
};
