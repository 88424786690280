
import NkHeading from '@/components/atoms/Heading';
import NkIcon from '@/components/atoms/Icon';
import { ref } from 'vue';

const __default__ = {
  name: 'NkConfiguratorCategories',
  components: {
    NkHeading,
    NkIcon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, context: any): any {
    return {
      toggle() {
        context.emit('toggle');
      },
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "70c040c2": (_ctx.$theme.colors.grey.light),
  "2cae91e7": (_ctx.$theme.borderRadius.sm),
  "06108985": (_ctx.$theme.spacing.md),
  "06108ae2": (_ctx.$theme.spacing.xl),
  "87fe5ce0": (_ctx.$theme.spacing.xl2),
  "06108969": (_ctx.$theme.spacing.lg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__