
import { ref } from 'vue';
import { merge } from 'lodash';
import panzoom from 'panzoom';

export default {
  props: {
    options: {
      type: Object,
      default: (): any => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(): any {
    const element = ref(null);
    let instance = ref(null);
    const defaultOptions = ref(null);
    return {
      element,
      instance,
      defaultOptions,
    };
  },
  mounted(): void {
    (this as any).defaultOptions = {
      bounds: true,
      boundsPadding: 1,
      minZoom: 1,
      maxZoom: 5,
    };
    if (!(this as any).disabled) {
      (this as any).instance = panzoom(
        (this as any).element,
        merge((this as any).defaultOptions, (this as any).options),
      );
    }
  },
  beforeUnmount(): void {
    (this as any).instance?.dispose();
  },
};
