
import { computed } from 'vue';
import NkButton from '@/components/atoms/Button';
import NkIcon from '@/components/atoms/Icon';
import { isWeb } from '@/utils/isWeb';
import { isLoggedIn } from '@/router';

const __default__ = {
  components: {
    NkButton,
    NkIcon,
  },

  props: {
    links: {
      type: Array,
      required: true,
    },
    rollable: {
      type: Boolean,
      default: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },

  setup(props: any): any {
    const filteredLinks = computed(() => {
      if (isWeb)
        return props.links.filter(
          (link: any) => link.id !== 6 && link.id !== 7,
        );

      if (isLoggedIn)
        return props.links.filter(
          (link: any) => link.route.name !== 'Login' && link.id !== 5,
        );

      return props.links.filter((link: any) => link.id !== 7);
    });

    return {
      filteredLinks,
      classes: computed(() => ({
        'nk-navigation': true,
        'nk-navigation--open': props.open && props.rollable,
        'nk-navigation--rollable': props.rollable,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "34fb392c": (_ctx.$theme.spacing.lg),
  "34fb376e": (_ctx.$theme.spacing.sm),
  "6a06a665": (_ctx.$theme.transitions.base),
  "34fb363a": (_ctx.$theme.spacing.xl),
  "4ad001d2": (_ctx.$theme.colors.primary.base),
  "1a5bec8a": (_ctx.$theme.borderRadius.full)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__