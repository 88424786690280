import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-xl bg-white" }
const _hoisted_2 = {
  key: 0,
  class: "mb-xl"
}
const _hoisted_3 = { class: "mb-lg" }
const _hoisted_4 = { class: "-mb-xl" }
const _hoisted_5 = { class: "mb-xl" }
const _hoisted_6 = { class: "mb-md" }
const _hoisted_7 = { class: "-mb-md" }
const _hoisted_8 = {
  key: 0,
  class: "inline-flex mt-md"
}
const _hoisted_9 = { class: "mb-xl" }
const _hoisted_10 = { class: "mb-md" }
const _hoisted_11 = { class: "-mb-md" }
const _hoisted_12 = { class: "mb-xl" }
const _hoisted_13 = { class: "mb-md" }
const _hoisted_14 = { class: "space-y-lg" }
const _hoisted_15 = { class: "mb-md" }
const _hoisted_16 = { class: "-mb-md flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_button = _resolveComponent("nk-button")!
  const _component_nk_heading = _resolveComponent("nk-heading")!
  const _component_nk_card_contact = _resolveComponent("nk-card-contact")!
  const _component_ConfiguratorOption = _resolveComponent("ConfiguratorOption")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modelValue: $setup.open,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ($setup.open = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.$store.state.app.deviceInfo?.platform === 'electron')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_nk_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.close())),
                type: "file"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.actions.close_application', true)), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_nk_heading, { type: "t3" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$trans('settings.title', true)), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_nk_heading, { type: "t4" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.profile.title', true)), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_nk_button, {
                onClick: $setup.openAdmin,
                class: "mb-md mr-md",
                type: "file"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.profile.edit', true)), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_nk_button, {
                onClick: $setup.logout,
                class: "mb-md mr-md",
                type: "file"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.profile.click_here_to_connect', true)), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            ($setup.currentUser)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_nk_card_contact, {
                    title: `${$setup.currentUser.firstName} ${$setup.currentUser.LastName}<br><b>${$setup.currentUser.company}</b>`,
                    media: $setup.currentUser.image?.uuid || $setup.currentUser.avatar,
                    email: $setup.currentUser.email,
                    tel: $setup.currentUser.phoneNumber
                  }, null, 8, ["title", "media", "email", "tel"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_nk_heading, { type: "t4" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.actions.title', true)), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_nk_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.dispatch('syncContent'))),
                class: "mb-md mr-md",
                type: "file"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.actions.sync_content', true)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_nk_button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.dispatch('downloadMedias'))),
                class: "mb-md mr-md",
                type: "file"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.actions.download', true)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_nk_button, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.dispatch('providers/contacts/send'))),
                class: "mb-md mr-md",
                type: "file"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.actions.send_emails', true)) + " (" + _toDisplayString(_ctx.$store.getters['providers/contacts/collection'].length) + ")", 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_nk_heading, { type: "t4" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.usage.title', true)), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_ConfiguratorOption, {
                selected: _ctx.$store.getters['settings/all'].offlineMode === true,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (
                _ctx.$store.dispatch('settings/set', {
                  property: 'offlineMode',
                  value: true,
                })
              )),
                name: _ctx.$trans('settings.usage.offline', true),
                description: _ctx.$trans('settings.usage.offline.description', true)
              }, null, 8, ["selected", "name", "description"]),
              _createVNode(_component_ConfiguratorOption, {
                selected: _ctx.$store.getters['settings/all'].offlineMode === false,
                onClick: _cache[5] || (_cache[5] = ($event: any) => (
                _ctx.$store.dispatch('settings/set', {
                  property: 'offlineMode',
                  value: false,
                })
              )),
                name: _ctx.$trans('settings.usage.online', true),
                description: _ctx.$trans('settings.usage.online.description', true)
              }, null, 8, ["selected", "name", "description"])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_nk_heading, { type: "t4" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.resources.title', true)), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_nk_button, {
                onClick: $setup.openFAQ,
                class: "mb-md mr-md",
                type: "file"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$trans('settings.resources.q&a', true)), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createElementVNode("div", null, [
                _createElementVNode("div", null, "Build number: " + _toDisplayString($setup.appInfo.build), 1),
                _createElementVNode("div", null, "Version number: " + _toDisplayString($setup.appInfo.version), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}