import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Transition as _Transition, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nk-navigation__list"
}
const _hoisted_2 = { class: "nk-navigation__toggle__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkButton = _resolveComponent("NkButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NkIcon = _resolveComponent("NkIcon")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass([$setup.classes, "pointer-events-none"])
  }, [
    ($props.open)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredLinks, (link) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nk-navigation__list__item",
              key: link.id
            }, [
              (link.route)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: link.route,
                    class: "pointer-events-auto"
                  }, {
                    default: _withCtx(() => [
                      (link.id === 6)
                        ? (_openBlock(), _createBlock(_component_NkButton, {
                            key: 0,
                            type: "icon",
                            "icon-name": link.icon,
                            size: $props.rollable ? 'sm' : '',
                            plain: true
                          }, null, 8, ["icon-name", "size"]))
                        : (_openBlock(), _createBlock(_component_NkButton, {
                            key: 1,
                            type: "icon",
                            "icon-name": link.icon,
                            size: $props.rollable ? 'sm' : '',
                            active: link.active
                          }, null, 8, ["icon-name", "size", "active"]))
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    ($props.rollable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "nk-navigation__toggle pointer-events-auto",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleOpen')))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_Transition, {
              "enter-from-class": "opacity-0 -translate-x-1",
              "enter-active-class": "transition duration-300 absolute inset-0 transform",
              "leave-to-class": "opacity-0 translate-x-1",
              "leave-active-class": "transition duration-300 absolute inset-0 transform"
            }, {
              default: _withCtx(() => [
                ($props.open)
                  ? (_openBlock(), _createBlock(_component_NkIcon, {
                      key: 0,
                      name: "burgerOpen"
                    }))
                  : (_openBlock(), _createBlock(_component_NkIcon, {
                      key: 1,
                      name: "burgerClose"
                    }))
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}