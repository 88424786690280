import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "nk-configuratorOptionExceptions" }
const _hoisted_2 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_heading = _resolveComponent("nk-heading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_nk_heading, {
      class: "uppercase",
      type: "t9"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$trans('configurator.option.exceptions.title', true)), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.exceptions, (exception, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `exception-${index}`,
          class: "nk-configuratorOptionExceptions__item"
        }, _toDisplayString(exception), 1))
      }), 128))
    ])
  ]))
}