export class ProviderManager {
  private instances: App.ProviderInstance[] = [];
  public add(providerInstance: App.ProviderInstance): void {
    this.instances.push(providerInstance);
  }
  public getAll(): App.ProviderInstance[] {
    return this.instances;
  }
}

export const providerManager = new ProviderManager();

export default providerManager;
