
import { reactive, computed } from 'vue';

const __default__ = {
  props: {
    tag: {
      type: String,
      default: 'h2',
    },
    content: {
      type: String,
    },
    type: {
      type: String,
      validator: function (value: string): boolean {
        return (
          ['t1', 't1Sub', 't2', 't3', 't4', 't5', 't6', 't7'].indexOf(value) !==
          -1
        );
      },
    },
    color: {
      type: String,
      validator: function (value: string): boolean {
        return (
          ['primary', 'secondary', 'tertiary', 'quaternary', 'quinary'].indexOf(
            value,
          ) !== -1
        );
      },
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-heading': true,
        [`nk-heading--${props.type}`]: props.type,
        [`nk-heading--${props.color || 'secondary'}`]: true,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ca98c036": (_ctx.$theme.fontFamily.sans1),
  "2bfd30b9": (_ctx.$theme.colors.primary.base),
  "6676b587": (_ctx.$theme.colors.secondary.base),
  "5abebcf1": (_ctx.$theme.colors.tertiary.base),
  "2f6a7b76": (_ctx.$theme.colors.quaternary.base),
  "f2bd4548": (_ctx.$theme.colors.quinary.base),
  "2e541c74": (_ctx.$theme.fontSize.xl6),
  "61485068": (_ctx.$theme.lineHeight.xs),
  "2e541c76": (_ctx.$theme.fontSize.xl5),
  "67badf62": (_ctx.$theme.lineHeight.base),
  "2e541c7a": (_ctx.$theme.fontSize.xl3),
  "2e541c78": (_ctx.$theme.fontSize.xl4),
  "2e541c7c": (_ctx.$theme.fontSize.xl2),
  "31de254d": (_ctx.$theme.fontSize.base),
  "61485330": (_ctx.$theme.lineHeight.md),
  "ca98c034": (_ctx.$theme.fontFamily.sans2),
  "72dd9c77": (_ctx.$theme.fontSize.xs),
  "61485368": (_ctx.$theme.lineHeight.lg),
  "ca98c032": (_ctx.$theme.fontFamily.sans3),
  "72dd9c70": (_ctx.$theme.fontSize.xl),
  "1c1f21e6": (_ctx.$theme.lineHeight.xs2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__