import { createProviderStoreInstance } from '@/modules/provider-manager';
import { galleriesProvider } from '@/entities/gallery/providers';

const providerStore = createProviderStoreInstance(galleriesProvider);

export const galleriesStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
  },
  getters: {
    ...providerStore.getters,
  },
  mutations: {
    ...providerStore.mutations,
  },
  actions: {
    ...providerStore.actions,
  },
};
