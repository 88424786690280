import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = {
  key: 0,
  class: "nk-configuratorOption__checkbox"
}
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "nk-configuratorOption__icon" }
const _hoisted_5 = { class: "nk-configuratorOption__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_heading = _resolveComponent("nk-heading")!
  const _component_nk_text = _resolveComponent("nk-text")!
  const _component_nk_configurator_option_exceptions = _resolveComponent("nk-configurator-option-exceptions")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nk-configuratorOption", [$props.selected ? 'selected' : '', $props.disabled ? 'disabled' : '']]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggle && $setup.toggle(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!$props.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "icon")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_nk_heading, { type: "t4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_nk_text, {
            type: "xs2",
            content: $props.description
          }, null, 8, ["content"])
        ])
      ])
    ]),
    ($props.exceptions && $props.disabled)
      ? (_openBlock(), _createBlock(_component_nk_configurator_option_exceptions, {
          key: 0,
          exceptions: $props.exceptions,
          class: "mt-sm"
        }, null, 8, ["exceptions"]))
      : _createCommentVNode("", true)
  ], 2))
}