
import { computed, ref } from 'vue';

const __default__ = {
  provide(): any {
    return {
      shared: (this as any).shared,
    };
  },

  setup(): any {
    const shared = ref({ selectedId: null });
    return {
      shared,
      classes: computed(() => ({
        'nk-dropdownList': true,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0718d60e": (_ctx.$theme.spacing.md),
  "0718d354": (_ctx.$theme.spacing.xl),
  "eaee69a0": (_ctx.$theme.colors.primary.lighter),
  "476c8e30": (_ctx.$theme.borderRadius.md),
  "52b87810": (_ctx.$theme.colors.grey.lightest),
  "0718d346": (_ctx.$theme.spacing.xs),
  "0718d646": (_ctx.$theme.spacing.lg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__