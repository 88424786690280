
import { computed } from 'vue';
import NkNavigation from '@/components/organisms/Navigation';
import router from '@/router';
import store from '@/store';

import { isWeb } from '@/utils/isWeb';
import { isLoggedIn } from '@/router';

export default {
  components: {
    NkNavigation,
  },
  setup(): any {
    const links = computed(() => [
      {
        id: 6,
        icon: 'profile',
        route: {
          name: 'Login',
        },
        active: router.currentRoute.value.name === 'Login',
        visibleWithoutLogin: true,
      },
      {
        id: 1,
        icon: 'gallery',
        route: {
          name: 'Gallery',
        },
        active: router.currentRoute.value.name === 'Gallery',
        visibleWithoutLogin: true,
      },
      {
        id: 2,
        icon: 'genesis',
        route: {
          name: 'Genesis',
        },
        active: router.currentRoute.value.name === 'Genesis',
        visibleWithoutLogin: true,
      },
      {
        id: 3,
        icon: 'technicalSpecs',
        route: {
          name: 'Specification',
        },
        active: router.currentRoute.value.name === 'Specification',
        visibleWithoutLogin: false,
      },
      {
        id: 4,
        icon: 'testimonials',
        route: {
          name: 'Testimony',
        },
        active: router.currentRoute.value.name === 'Testimony',
        visibleWithoutLogin: false,
      },
      {
        id: 5,
        icon: 'contact',
        route: {
          name: 'ContactWeb',
        },
        active: router.currentRoute.value.name === 'ContactWeb',
        visibleWithoutLogin: true,
      },
      {
        id: 7,
        icon: 'contact',
        route: {
          name: 'Contact',
        },
        active: router.currentRoute.value.name === 'Contact',
        visibleWithoutLogin: false,
      },
    ]);
    return {
      links,
      open: computed(() => {
        return store.getters['navigationOpen'];
      }),
    };
  },
  methods: {
    toggleOpen(): void {
      store.dispatch('toggleNavigationOpen');
    },
  },
};
