
import { computed } from 'vue';
import NkOverlayScrollbars from '@/components/atoms/OverlayScrollbars';

const __default__ = {
  components: {
    NkOverlayScrollbars,
  },

  props: {
    content: {
      type: String,
    },
  },

  setup(): any {
    return {
      classes: computed(() => ({
        'nk-table': true,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f9356bd4": (_ctx.$theme.fontFamily.sans2),
  "fa135772": (_ctx.$theme.fontSize.xs),
  "3c25da1c": (_ctx.$theme.lineHeight.lg),
  "a3b02d52": (_ctx.$theme.colors.secondary.base),
  "e458fc58": (_ctx.$theme.spacing.md),
  "f9356bd6": (_ctx.$theme.fontFamily.sans1),
  "4857981c": (_ctx.$theme.fontSize.xl2),
  "3c25db9c": (_ctx.$theme.lineHeight.xs),
  "9c9cc9da": (_ctx.$theme.colors.grey.lightest),
  "2c9ce321": (_ctx.$theme.spacing.xl2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__