
import { defineComponent, ref } from 'vue';
import { ElDialog } from 'element-plus';
import NkButton from '@/components/atoms/Button';
import NkOverlayScrollbars from '@/components/atoms/OverlayScrollbars';
import NkPanZoom from '@/components/atoms/PanZoom';
import 'element-plus/lib/components/dialog/style';
export default defineComponent({
  components: { ElDialog, NkButton, NkOverlayScrollbars, NkPanZoom },
  props: {
    hideClose: {
      type: Boolean,
      default: false,
    },
    overflow: {
      type: Boolean,
      default: false,
    },
    hasPanZoom: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, context: any): any {
    const panZoom = ref(null);
    const closeButton = ref(null);
    return {
      panZoom,
      closeButton,
      close() {
        context.emit('update:modelValue', false);
        (panZoom.value as any).instance?.zoomTo(0, 0, 0);
      },
      panZoomOptions() {
        return {
          onTouch: function (e: any) {
            // `e` - is current touch event.
            if (
              closeButton.value &&
              e.target.closest('#closeButton') ===
                (closeButton.value as any).$el
            ) {
              return false; // tells the library to not preventDefault.
            }
            e.preventDefault();
            e.stopPropagation();
          },
        };
      },
    };
  },
});
