import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nk-localeSwitch__active__name" }
const _hoisted_2 = {
  key: 0,
  class: "nk-localeSwitch__active__icon"
}
const _hoisted_3 = {
  key: 0,
  class: "nk-localeSwitch__list"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkIcon = _resolveComponent("NkIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes),
    ref: "target"
  }, [
    _createElementVNode("div", {
      class: "nk-localeSwitch__active",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.open = !$setup.open))
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString($props.currentLocale), 1),
      ($options.availableLocales.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_NkIcon, { name: "angle" })
          ]))
        : _createCommentVNode("", true)
    ]),
    ($options.availableLocales.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.availableLocales, (locale) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nk-localeSwitch__list__item",
              key: locale,
              onClick: ($event: any) => ($options.setLocale(locale))
            }, _toDisplayString(locale), 9, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}