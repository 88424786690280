
import { reactive, computed } from 'vue';
import NkIcon from '@/components/atoms/Icon';

const __default__ = {
  components: {
    NkIcon,
  },

  props: {
    tag: {
      type: String,
      default: 'button',
    },
    content: {
      type: String,
    },
    iconName: {
      type: String,
    },
    iconSize: {
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: function (value: string): boolean {
        return ['xs', 'sm'].indexOf(value) !== -1;
      },
    },
    type: {
      type: String,
      validator: function (value: string): boolean {
        return ['icon', 'submit', 'play', 'file'].indexOf(value) !== -1;
      },
    },
    plain: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      validator: function (value: string): boolean {
        return (
          ['primary', 'secondary', 'tertiary', 'quaternary', 'quinary'].indexOf(
            value,
          ) !== -1
        );
      },
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-button': true,
        'nk-button--active': props.active,
        'nk-button--plain': props.plain,
        [`nk-button--${props.type}`]: props.type,
        [`nk-button--${props.size}`]: props.size,
        [`nk-button--${props.color || 'primary'}`]: true,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0259ab27": (_ctx.$theme.spacing.xl),
  "38118553": (_ctx.$theme.colors.primary.base),
  "302feef7": (_ctx.$theme.borderRadius.full),
  "38a1bcea": (_ctx.$theme.fontFamily.sans1),
  "c195a9ec": (_ctx.$theme.fontSize.xl),
  "6a1590fc": (_ctx.$theme.lineHeight.base),
  "462da2fe": (_ctx.$theme.colors.primary.lighter),
  "38126df8": (_ctx.$theme.colors.primary.dark),
  "826f68be": (_ctx.$theme.colors.secondary.base),
  "a6cfc51a": (_ctx.$theme.colors.secondary.lighter),
  "5d9608d2": (_ctx.$theme.colors.tertiary.base),
  "7aae3c86": (_ctx.$theme.colors.tertiary.lighter),
  "5d943788": (_ctx.$theme.colors.tertiary.dark),
  "cba8212a": (_ctx.$theme.colors.quaternary.base),
  "3cc96d69": (_ctx.$theme.colors.quaternary.lighter),
  "cba64fe0": (_ctx.$theme.colors.quaternary.dark),
  "da949c14": (_ctx.$theme.colors.quinary.base),
  "37e0ca04": (_ctx.$theme.colors.quinary.lighter),
  "da92caca": (_ctx.$theme.colors.quinary.dark),
  "7d645e4c": (_ctx.$theme.colors.error.light),
  "b9c23ae6": (_ctx.$theme.colors.error.base),
  "0259a9ae": (_ctx.$theme.spacing.lg),
  "c195aca6": (_ctx.$theme.fontSize.md),
  "48dbbac4": (_ctx.$theme.spacing.xs2),
  "0259a9ca": (_ctx.$theme.spacing.md),
  "2029ee2a": (_ctx.$theme.colors.grey.base),
  "bc9270ee": (_ctx.$theme.colors.grey.lightest),
  "c195a9de": (_ctx.$theme.fontSize.xs)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__