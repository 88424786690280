import { orderBy } from 'lodash';

export const createProviderStoreInstance = (
  providerInstance: App.ProviderInstance,
): App.ProviderStoreInstance => {
  return {
    state: {
      collection: [],
    },
    getters: {
      collection(state: any) {
        return orderBy(
          state.collection.filter((item: any) =>
            'isOnline' in item ? item.isOnline : item,
          ),
          ['position'],
          ['asc'],
        );
      },
      getItem: (state: any, getters: any) => (id: string | number) => {
        return getters['collection'].find((item: any) => item.id === id);
      },
    },
    mutations: {
      setCollection(state: any, value: any) {
        state.collection = value;
      },
    },
    actions: {
      deleteAllDocs({ dispatch }: any) {
        return providerInstance
          .readAllDocs()
          .then((data) => {
            return providerInstance.deleteDocs(data);
          })
          .then(() => {
            dispatch('setCollection', []);
            return Promise.resolve([]);
          });
      },
      setData({ dispatch }: any, data: any) {
        return providerInstance
          .setData(data)
          .then(() => {
            return providerInstance.readAllDocs();
          })
          .then((data: any) => {
            dispatch('setCollection', data);
            return Promise.resolve(data);
          });
      },
      setCollection({ commit }: any, value: any) {
        commit('setCollection', value);
      },
      async fetch({ dispatch }: any) {
        return providerInstance
          .fetchDB()
          .then((data) => {
            dispatch('setCollection', data);
            return data;
          })
          .then(async (data) => {
            return providerInstance.readAllDocs().then(async (docs) => {
              const docsToDelete = docs.filter(
                (doc: any) => !data.find((item: any) => item.id === doc.id),
              );
              await providerInstance.deleteDocs(docsToDelete);
              return data;
            });
          });
      },
    },
  };
};
