
import { computed, ref } from 'vue';
import BackToHomeButton from '@/entities/app/components/actions/BackToHomeButton.vue';
import Navigation from '@/entities/app/components/actions/Navigation.vue';
import NkOverlayScrollbars from '@/components/atoms/OverlayScrollbars';

const __default__ = {
  components: {
    BackToHomeButton,
    Navigation,
    NkOverlayScrollbars,
  },

  props: {
    asideFull: {
      type: Boolean,
      default: false,
    },
  },

  setup(): any {
    const tabsHeight = ref(null);
    return {
      tabsHeight,
      classes: computed(() => ({
        'nk-pageArgument': true,
      })),
    };
  },

  mounted(): void {
    (this as any).tabsHeight = (this as any).$refs['tabs'].offsetHeight
      ? `${(this as any).$refs['tabs'].offsetHeight}px`
      : '4.5rem';
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2415e65b": (_ctx.$theme.colors.primary.lightest),
  "86b998d8": (_ctx.$theme.spacing.xl2),
  "ad5b3ee2": (_ctx.tabsHeight),
  "0e57d85e": (_ctx.$theme.spacing.xl)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__