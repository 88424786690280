
import { IonContent, IonPage, IonGrid, IonCol, IonRow } from '@ionic/vue';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElCheckbox,
} from 'element-plus';
import 'element-plus/dist/index.css';
import { defineComponent, ref } from 'vue';
import { cloneDeep } from 'lodash';
import { api } from '@/services';
export default defineComponent({
  name: 'ContactWeb',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElCheckbox,
  },
  setup() {
    const formEl: any = ref(null);
    const form = ref({
      contact: {
        lastname: '',
        firstname: '',
        company: '',
        profession: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
        phone: '',
        email: '',
      },
      entity: '',
      request: '',
      message: '',
      consent: false,
    });
    const rules = ref({
      entity: [
        {
          required: true,
          trigger: 'change',
          message: 'This field is required.',
        },
      ],
      request: [
        {
          required: true,
          trigger: 'change',
          message: 'This field is required.',
        },
      ],
      'contact.lastname': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.firstname': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.address': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.zipCode': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.city': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.country': [
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      'contact.phone': [
        {
          type: 'tel',
          trigger: 'blur',
          validator: (rule: any, value: string, callback: any) => {
            const valid = value.match(
              /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
            );
            if (valid) {
              return callback();
            } else if (!value && !rule.required) {
              return callback();
            }
            return callback(
              new Error(
                (this as any).$trans(
                  'contact.form.entity.validation.phone_valid',
                  true,
                ),
              ),
            );
          },
        },
      ],
      'contact.email': [
        {
          type: 'email',
          trigger: 'blur',
          message: 'This is not a valid email.',
        },
        {
          required: true,
          trigger: 'blur',
          message: 'This field is required.',
        },
      ],
      consent: [
        {
          trigger: 'change',
          validator: (rule: any, value: string, callback: any) => {
            if (value) return callback();
            return callback(
              new Error(
                (this as any).$trans(
                  'contact.form.entity.validation.consent_valid',
                  true,
                ),
              ),
            );
          },
        },
      ],
    });
    const dialogVisible = ref(false);
    const formNotification = ref({
      message: '',
      type: '',
    });
    return {
      formEl,
      form,
      rules,
      dialogVisible,
      formNotification,
    };
  },
  computed: {
    entityOptions() {
      return [
        {
          value: 'entity_local_authority',
          label: (this as any).$trans(
            'contact.form.entity.option.local_authority',
            true,
          ),
        },
        {
          value: 'entity_distributor',
          label: (this as any).$trans(
            'contact.form.entity.option.distributor',
            true,
          ),
        },
        {
          value: 'entity_private_individual',
          label: (this as any).$trans(
            'contact.form.entity.option.private_individual',
            true,
          ),
        },
        {
          value: 'entity_professional',
          label: (this as any).$trans(
            'contact.form.entity.option.professional',
            true,
          ),
        },
        {
          value: 'entity_landscaper',
          label: (this as any).$trans(
            'contact.form.entity.option.landscaper',
            true,
          ),
        },
        {
          value: 'entity_theme_park',
          label: (this as any).$trans(
            'contact.form.entity.option.theme_park',
            true,
          ),
        },
        {
          value: 'entity_service_company',
          label: (this as any).$trans(
            'contact.form.entity.option.service_company',
            true,
          ),
        },
        {
          value: 'entity_other',
          label: (this as any).$trans('contact.form.entity.option.other', true),
        },
      ];
    },
    requestOptions() {
      return [
        {
          value: 'request_quote_request',
          label: (this as any).$trans(
            'contact.form.request.option.quote_request',
            true,
          ),
        },
        {
          value: 'request_demonstration',
          label: (this as any).$trans(
            'contact.form.request.option.demonstration',
            true,
          ),
        },
        {
          value: 'request_documentation',
          label: (this as any).$trans(
            'contact.form.request.option.documentation',
            true,
          ),
        },
        {
          value: 'request_financing_offer',
          label: (this as any).$trans(
            'contact.form.request.option.financing_offer',
            true,
          ),
        },
        {
          value: 'request_other',
          label: (this as any).$trans(
            'contact.form.request.option.other',
            true,
          ),
        },
      ];
    },
  },
  methods: {
    submit() {
      (this as any).formEl
        .validate()
        .then(async () => {
          const data = cloneDeep(this.form);
          api
            .post('/contact/send-via-url', data)
            .then(() => {
              (this as any).formNotification.message = (this as any).$trans(
                'contact.form.message.success',
                true,
              );
              (this as any).formNotification.type = 'success';
              (this as any).dialogVisible = true;
              (this as any).formEl.resetFields();
            })
            .catch(() => {
              (this as any).formNotification.message = (this as any).$trans(
                'contact.form.message.error.request',
                true,
              );
              (this as any).formNotification.type = 'error';
              (this as any).dialogVisible = true;
            });
        })
        .catch(async () => {
          (this as any).formNotification.message = (this as any).$trans(
            'contact.form.message.error.validation',
            true,
          );
          (this as any).formNotification.type = 'error';
          (this as any).dialogVisible = true;
        });
    },
  },
});
