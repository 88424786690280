import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NkNavigation = _resolveComponent("NkNavigation")!

  return (_openBlock(), _createBlock(_component_NkNavigation, {
    links: $setup.links,
    open: $setup.open,
    onToggleOpen: $options.toggleOpen
  }, null, 8, ["links", "open", "onToggleOpen"]))
}