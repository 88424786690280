import { createProviderStoreInstance } from '@/modules/provider-manager';
import { usersProvider } from '@/entities/user/providers';
const providerStore = createProviderStoreInstance(usersProvider);

export const usersStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
  },
  getters: {
    ...providerStore.getters,
  },
  mutations: {
    ...providerStore.mutations,
  },
  actions: {
    ...providerStore.actions,
  },
};
