
import { ref } from 'vue';
import NkButton from '@/components/atoms/Button';

export default {
  components: {
    NkButton,
  },
  setup(): any {
    const homeLink = ref({
      icon: 'home',
      route: {
        name: 'Home',
      },
    });
    return {
      homeLink,
    };
  },
};
