
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Gallery',
  components: {
    IonContent,
    IonPage,
  },
  data() {
    return {
      dialogVisible: false,
      selectedGalleryItem: null,
    };
  },
  methods: {
    showGalleryItem(item: any) {
      (this as any).dialogVisible = true;
      (this as any).selectedGalleryItem = item;
    },
  },
});
