import { createProviderStoreInstance } from '@/modules/provider-manager';
import { contactsProvider } from '@/entities/contact/providers';
import { v4 as uuid } from 'uuid';
import { api } from '@/services';
import { Promise } from 'bluebird';
import {
  convertFileDataToBlob,
  convertBase64DataToBlob,
  Filesystem,
} from '@/modules/filesystem';
import { flatten } from 'lodash';
import { toastController } from '@ionic/vue';
const providerStore = createProviderStoreInstance(contactsProvider);
export const contactsStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
    sending: false,
  },
  getters: {
    ...providerStore.getters,
  },
  mutations: {
    ...providerStore.mutations,
    setSending(state: any, value: boolean) {
      state.sending = value;
    },
  },
  actions: {
    ...providerStore.actions,
    setSending({ commit }: any, value: boolean) {
      commit('setSending', value);
    },
    add({ commit, dispatch }: any, item: any): Promise<any> {
      return contactsProvider
        .setData({
          ...item,
          id: item.id || uuid(),
        })
        .then((result) => {
          dispatch('fetch');
          return result;
        });
    },
    async send({ dispatch, state }: any): Promise<any> {
      if (state.sending) {
        return Promise.reject(new Error('Already Sending Mails.'));
      }
      const toast = await toastController.create({
        message: 'Sending Emails',
        duration: 3000,
        cssClass: 'text-center',
        color: 'primary',
      });
      await toast.present();
      dispatch('setSending', true);
      return contactsProvider
        .readAllDocs()
        .then(async (docs: any[]) => {
          const formData = new FormData();
          const attachments = flatten(
            await Promise.map(docs, (doc) => {
              const docAttachments = doc.attachments || [];
              return Promise.map(
                docAttachments,
                async ({ fileName, path, directory, base64 }: any) => {
                  let contents: any = {};
                  try {
                    contents = await Filesystem.readFile({
                      path,
                      directory,
                    });
                  } catch (e: any) {
                    console.warn(e.message);
                  }
                  return {
                    blob: base64
                      ? await convertBase64DataToBlob(base64)
                      : convertFileDataToBlob(contents.data),
                    name: fileName,
                  };
                },
              );
            }),
          );
          const docsWithoutBase64 = docs.map((doc: any) => {
            return {
              ...doc,
              attachments: doc.attachments.map((attachment: any) => ({
                ...attachment,
                base64: null,
              })),
            };
          });
          formData.append('contacts', JSON.stringify(docsWithoutBase64));
          attachments.forEach(({ blob, name }) => {
            formData.append('attachments[]', blob, name);
          });
          await api.post('/contact/send', formData);
          return docs;
        })
        .then((docs: any[]) => {
          return contactsProvider.deleteDocs(docs);
        })
        .finally(async () => {
          await dispatch('fetch');
          dispatch('setSending', false);
        });
    },
  },
};
