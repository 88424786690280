import app from '@/app';

/* Atoms */
import Button from '@/components/atoms/Button';
import Heading from '@/components/atoms/Heading';
import Icon from '@/components/atoms/Icon';
import Image from '@/components/atoms/Image';
import Table from '@/components/atoms/Table';
import Text from '@/components/atoms/Text';
import Media from '@/components/atoms/Media';
import OverlayScrollbars from '@/components/atoms/OverlayScrollbars';

/* Molecules */
import CardImage from '@/components/molecules/CardImage';
import CardQuote from '@/components/molecules/CardQuote';
import CardContact from '@/components/molecules/CardContact';
import CardMessage from '@/components/molecules/CardMessage';
import Dropdown from '@/components/molecules/Dropdown';
import DropdownItem from '@/components/molecules/DropdownItem';
import DropdownList from '@/components/molecules/DropdownList';
import LocaleSwitch from '@/components/molecules/LocaleSwitch';
import Tile from '@/components/molecules/Tile';

/* Organisms */
import Navigation from '@/components/organisms/Navigation';
import NavigationList from '@/components/organisms/NavigationList';
import NavigationListItem from '@/components/organisms/NavigationListItem';
import Slider from '@/components/organisms/Slider';
import SliderSlide from '@/components/organisms/SliderSlide';

/* Templates */
import Dialog from '@/components/templates/Dialog';
import Home from '@/components/templates/Home';
import PageArgument from '@/components/templates/PageArgument';
import Page from '@/components/templates/Page';

/* Atoms */
app.component('nk-button', Button);
app.component('nk-heading', Heading);
app.component('nk-icon', Icon);
app.component('nk-image', Image);
app.component('nk-table', Table);
app.component('nk-text', Text);
app.component('nk-media', Media);
app.component('nk-overlay-scrollbars', OverlayScrollbars);

/* Molecules */
app.component('nk-card-image', CardImage);
app.component('nk-card-quote', CardQuote);
app.component('nk-card-contact', CardContact);
app.component('nk-card-message', CardMessage);
app.component('nk-dropdown', Dropdown);
app.component('nk-dropdown-item', DropdownItem);
app.component('nk-dropdown-list', DropdownList);
app.component('nk-locale-switch', LocaleSwitch);
app.component('nk-tile', Tile);

/* Organisms */
app.component('nk-navigation', Navigation);
app.component('nk-navigation-list', NavigationList);
app.component('nk-navigation-list-item', NavigationListItem);
app.component('nk-slider', Slider);
app.component('nk-slider-slide', SliderSlide);

/* Templates */
app.component('nk-dialog', Dialog);
app.component('nk-home', Home);
app.component('nk-page-argument', PageArgument);
app.component('nk-page', Page);
