
import { reactive, computed } from 'vue';
import NkButton from '@/components/atoms/Button';

const __default__ = {
  components: {
    NkButton,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator: function (value: string): boolean {
        return ['success', 'error'].indexOf(value) !== -1;
      },
    },
  },

  setup(props: any): any {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'nk-cardMessage': true,
        [`nk-cardMessage--${props.type || 'success'}`]: true,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "b3a03ab6": (_ctx.$theme.spacing.xl3),
  "57f105f1": (_ctx.$theme.spacing.md),
  "b3a03ab8": (_ctx.$theme.spacing.xl2),
  "7224cd32": (_ctx.$theme.borderRadius.base),
  "57f105d5": (_ctx.$theme.spacing.lg),
  "38648472": (_ctx.$theme.fontFamily.sans1),
  "06ed587a": (_ctx.$theme.fontSize.xl),
  "3b44b535": (_ctx.$theme.lineHeight.base),
  "2e9b1e3b": (_ctx.$theme.colors.primary.light),
  "8393d3fe": (_ctx.$theme.colors.error.light)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__