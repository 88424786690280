
import { computed } from 'vue';
import NkText from '@/components/atoms/Text';

const __default__ = {
  components: {
    NkText,
  },

  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
      required: true,
    },
  },

  setup(): any {
    return {
      classes: computed(() => ({
        'nk-dropdownItem': true,
      })),
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "72b4d50c": (_ctx.$theme.borderRadius.full),
  "26158bb0": (_ctx.$theme.colors.primary.base)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__