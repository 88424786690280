import { clearAuthTokens, setAuthTokens } from 'axios-jwt';
import axios from 'axios';

export const logout = async () => {
  await clearAuthTokens();
};
export const login = async () => {
  const { data }: any = await axios.post(
    `${process.env.VUE_APP_API_BASE_URL}/login_check`,
    {
      username: process.env.VUE_APP_API_LOGIN,
      password: process.env.VUE_APP_API_PASSWORD,
    },
  );
  setAuthTokens({
    accessToken: data.token,
    refreshToken: data.refresh_token,
  });
  return data;
};
