import { toastController } from '@ionic/vue';

import { Device } from '@/modules/device';

export const appStorePlugin = (store: any) => {
  let networkStatusPromise: any = null;
  store.registerModule('app', {
    namespaced: true,
    state: {
      debugEnabled: false,
      networkStatus: null,
      deviceInfo: null,
    },
    getters: {
      debug(state: any) {
        return state.debugEnabled || process.env.NODE_ENV === 'development';
      },
      isOnline(state: any) {
        return state.networkStatus ? state.networkStatus.connected : false;
      },
    },
    mutations: {
      setNetworkStatus(state: any, value: any) {
        state.networkStatus = value;
      },
      toggleDebug(state: any, value: any) {
        state.debug = value;
      },
      setDeviceInfo(state: any, value: any) {
        state.deviceInfo = value;
      },
    },
    actions: {
      toggleDebug({ commit }: any) {
        commit('toggleDebug');
      },
      setNetworkStatus({ commit }: any, value: any) {
        commit('setNetworkStatus', value);
      },
      setDeviceInfo({ commit }: any, value: any) {
        commit('setDeviceInfo', value);
      },
      async updateDeviceInfo({ dispatch }: any) {
        dispatch('setDeviceInfo', await Device.getInfo());
      },
      async updateNetworkStatus({ dispatch, state }: any, status?: any) {
        // if called multiple times, await the result of the first call
        if (networkStatusPromise) {
          return await networkStatusPromise;
        }
        networkStatusPromise = new Promise(async (resolve: any) => {
          status =
            status ||
            (await fetch(process.env.VUE_APP_PING_ENDPOINT as string)
              .then(() => ({ connected: true }))
              .catch(() => ({ connected: false })));
          if (
            (store.getters['app/debug'] && !state.networkStatus) ||
            (store.getters['app/debug'] &&
              state.networkStatus &&
              state.networkStatus.connected !== status.connected)
          ) {
            const toast = await toastController.create({
              message: `Network status changed: ${
                status.connected ? 'Online' : 'Offline'
              }`,
              duration: 3000,
              cssClass: 'text-center',
            });
            await toast.present();
          }

          await dispatch('setNetworkStatus', status);
          resolve(status);

          // make sure the function is called only once every xyz interval
          setTimeout(() => {
            networkStatusPromise = null;
          }, Number(process.env.VUE_APP_PING_INTERVAL));
        });
      },
    },
  });

  store.dispatch('app/updateDeviceInfo');
  store.dispatch('app/updateNetworkStatus');

  setInterval(
    () => store.dispatch('app/updateNetworkStatus'),
    Number(process.env.VUE_APP_PING_INTERVAL),
  );
};
