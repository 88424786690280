import { createProviderStoreInstance } from '@/modules/provider-manager';
import { userProvider } from '@/entities/user/providers';
const providerStore = createProviderStoreInstance(userProvider);

export const userStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
  },
  getters: {
    ...providerStore.getters,
  },
  mutations: {
    ...providerStore.mutations,
  },
  actions: {
    ...providerStore.actions,
  },
};
