import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { ref: "root" }
const _hoisted_2 = {
  key: 1,
  class: "absolute top-0 left-1/2 transform -translate-x-1/2 flex flex-col items-center px-lg py-md bg-white rounded-b-lg"
}
const _hoisted_3 = {
  key: 2,
  class: "absolute z-20 inset-x-0 bottom-48 flex justify-center"
}
const _hoisted_4 = { class: "mb-lg" }
const _hoisted_5 = {
  key: 0,
  class: "mb-xl"
}
const _hoisted_6 = {
  key: 1,
  class: "mb-xl3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_media = _resolveComponent("nk-media")!
  const _component_nk_button = _resolveComponent("nk-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_nk_heading = _resolveComponent("nk-heading")!
  const _component_nk_text = _resolveComponent("nk-text")!
  const _component_nk_dropdown_item = _resolveComponent("nk-dropdown-item")!
  const _component_nk_dropdown = _resolveComponent("nk-dropdown")!
  const _component_nk_dropdown_list = _resolveComponent("nk-dropdown-list")!
  const _component_nk_navigation_list_item = _resolveComponent("nk-navigation-list-item")!
  const _component_nk_navigation_list = _resolveComponent("nk-navigation-list")!
  const _component_nk_page_argument = _resolveComponent("nk-page-argument")!
  const _component_nk_dialog = _resolveComponent("nk-dialog")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.currentArgument)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_nk_page_argument, {
                    "aside-full": _ctx.currentArgument.isConfigurable,
                    key: _ctx.currentArgument.id
                  }, {
                    aside: _withCtx(() => [
                      (_ctx.currentMedia)
                        ? (_openBlock(), _createElementBlock("div", {
                            class: "absolute inset-0",
                            key: _ctx.currentMedia.id,
                            ref: "media"
                          }, [
                            (_ctx.currentMedia.type === 'video')
                              ? (_openBlock(), _createBlock(_component_nk_media, {
                                  key: 0,
                                  media: _ctx.currentMedia,
                                  controls: false,
                                  autoplay: true,
                                  loop: true,
                                  muted: true,
                                  class: "w-full h-full relative z-0"
                                }, null, 8, ["media"]))
                              : (_openBlock(), _createBlock(_component_nk_media, {
                                  key: 1,
                                  media: _ctx.currentMedia,
                                  class: "w-full h-full"
                                }, null, 8, ["media"]))
                          ], 512))
                        : _createCommentVNode("", true),
                      (
                  (_ctx.currentMedia && _ctx.currentMedia.type === 'video') ||
                  _ctx.currentMediaDetail
                )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (_ctx.currentMedia && _ctx.currentMedia.type === 'video')
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: _ctx.currentMedia.id
                                }, [
                                  (!_ctx.videoPlaying)
                                    ? (_openBlock(), _createBlock(_component_nk_button, {
                                        key: 0,
                                        type: "play",
                                        "icon-name": "play",
                                        onClick: _ctx.switchVideoPlayback
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$trans('action.play', true)), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"]))
                                    : (_openBlock(), _createBlock(_component_nk_button, {
                                        key: 1,
                                        type: "play",
                                        "icon-name": "pause",
                                        onClick: _ctx.switchVideoPlayback
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$trans('action.pause', true)), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"]))
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.currentMediaDetail)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: _ctx.currentMediaDetail.id
                                }, [
                                  _createVNode(_component_nk_button, {
                                    type: "play",
                                    "icon-name": "plus",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = true))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$trans('action.know_more', true)), 1)
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.currentArgument.isConfigurable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_router_link, { to: { name: 'Configurator' } }, {
                              default: _withCtx(() => [
                                _createVNode(_component_nk_button, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$trans('action.show_configurator', true)), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    main: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_nk_heading, {
                          type: "t1",
                          color: _ctx.currentArgument.color
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$trans(_ctx.currentArgument.translations).title), 1)
                          ]),
                          _: 1
                        }, 8, ["color"])
                      ]),
                      (_ctx.$trans(_ctx.currentArgument.translations).catchPhrase)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_nk_heading, { type: "t1Sub" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$trans(_ctx.currentArgument.translations).catchPhrase), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.$trans(_ctx.currentArgument.translations).content)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_nk_text, {
                              content: _ctx.$trans(_ctx.currentArgument.translations).content
                            }, null, 8, ["content"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.currentArgument.argumentBenefits.length > 0)
                        ? (_openBlock(), _createBlock(_component_nk_dropdown_list, { key: 2 }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentArgumentBenefits, (benefit) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: benefit.id
                                }, [
                                  _createVNode(_component_nk_dropdown, {
                                    title: _ctx.$trans(benefit.translations).title,
                                    onOpen: ($event: any) => (_ctx.currentBenefit = benefit),
                                    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentBenefit = null))
                                  }, {
                                    default: _withCtx(() => [
                                      (_ctx.$trans(benefit.translations).content)
                                        ? (_openBlock(), _createBlock(_component_nk_dropdown_item, {
                                            key: 0,
                                            content: _ctx.$trans(benefit.translations).content,
                                            class: "pb-md"
                                          }, null, 8, ["content"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1032, ["title", "onOpen"])
                                ]))
                              }), 128))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    tabs: _withCtx(() => [
                      _createVNode(_component_nk_navigation_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                    'providers/arguments/collection'
                  ], (argument) => {
                            return (_openBlock(), _createBlock(_component_nk_navigation_list_item, {
                              key: argument.id
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_router_link, {
                                  to: {
                      name: 'Argument',
                      query: { id: argument.id },
                    }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_nk_button, {
                                      color: argument.color,
                                      active: _ctx.currentArgument.id === argument.id,
                                      class: "w-full whitespace-nowrap",
                                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentBenefit = null))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$trans(argument.translations).title), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["color", "active"])
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["aside-full"]),
                  (_ctx.currentMediaDetail)
                    ? (_openBlock(), _createBlock(_component_nk_dialog, {
                        key: 0,
                        modelValue: _ctx.dialogVisible,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialogVisible = $event)),
                        "has-pan-zoom": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_nk_media, {
                            media: _ctx.currentMediaDetail,
                            key: _ctx.currentMediaDetail.id,
                            fit: "contain",
                            class: "w-full h-full"
                          }, null, 8, ["media"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}