import { createProviderStoreInstance } from '@/modules/provider-manager';
import { awardsProvider } from '@/entities/award/providers';

const providerStore = createProviderStoreInstance(awardsProvider);

export const awardsStore = {
  namespaced: true,
  state: {
    ...providerStore.state,
  },
  getters: {
    ...providerStore.getters,
  },
  mutations: {
    ...providerStore.mutations,
  },
  actions: {
    ...providerStore.actions,
  },
};
