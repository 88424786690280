import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nk-configuratorOptionExtended__checkbox"
}
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "nk-configuratorOptionExtended__icon" }
const _hoisted_4 = { class: "nk-configuratorOptionExtended__text" }
const _hoisted_5 = { class: "nk-configuratorOptionExtended__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_heading = _resolveComponent("nk-heading")!
  const _component_nk_text = _resolveComponent("nk-text")!
  const _component_nk_configurator_option_exceptions = _resolveComponent("nk-configurator-option-exceptions")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nk-configuratorOptionExtended", [$props.selected ? 'selected' : '', $props.disabled ? 'disabled' : '']])
  }, [
    _createElementVNode("div", {
      class: "flex items-center cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggle && $setup.toggle(...args)))
    }, [
      (!$props.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "icon")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_nk_heading, { type: "t4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_nk_text, {
            type: "xs2",
            content: $props.description
          }, null, 8, ["content"])
        ])
      ])
    ]),
    ($props.exceptions && $props.disabled)
      ? (_openBlock(), _createBlock(_component_nk_configurator_option_exceptions, {
          key: 0,
          exceptions: $props.exceptions,
          class: "mt-sm"
        }, null, 8, ["exceptions"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default")
    ], 512), [
      [_vShow, $props.selected]
    ])
  ], 2))
}