
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import Settings from '@/entities/settings/components/Settings.vue';

const __default__ = defineComponent({
  name: 'App',
  components: {
    Settings,
    IonApp,
    IonRouterOutlet,
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "63107ce0": (_ctx.$theme.spacing.md),
  "63107a26": (_ctx.$theme.spacing.xl),
  "772d8215": (_ctx.$theme.colors.grey.dark),
  "56da0a8b": (_ctx.$theme.fontSize.lg),
  "120d64f6": (_ctx.$theme.lineHeight.base),
  "3ab1ccd1": (_ctx.$theme.fontFamily.sans1),
  "63107a18": (_ctx.$theme.spacing.xs),
  "63107b5a": (_ctx.$theme.spacing.sm),
  "63107d18": (_ctx.$theme.spacing.lg),
  "56da0c0b": (_ctx.$theme.fontSize.xs),
  "15465fcf": (_ctx.$theme.colors.grey.lightest),
  "90aa6766": (_ctx.$theme.colors.primary.base),
  "90a8961c": (_ctx.$theme.colors.primary.dark),
  "6ef6f657": (_ctx.$theme.colors.grey.light),
  "56da0b6a": (_ctx.$theme.fontSize.sm),
  "56da0aa7": (_ctx.$theme.fontSize.md)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__