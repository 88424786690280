
import NkHeading from '@/components/atoms/Heading';

const __default__ = {
  components: {
    NkHeading,
  },
  props: {
    exceptions: {
      type: Array,
      default: (): [] => [],
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "397fb459": (_ctx.$theme.spacing.sm),
  "397fb4fa": (_ctx.$theme.spacing.xs),
  "e1a7e9d2": (_ctx.$theme.borderRadius.xs),
  "51e40255": (_ctx.$theme.colors.grey.lightest),
  "4d3afe0d": (_ctx.$theme.fontSize.xs2),
  "13122b10": (_ctx.$theme.spacing.xs2),
  "343cbb52": (_ctx.$theme.fontFamily.sans1),
  "14426a55": (_ctx.$theme.colors.secondary.base)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__