
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import NkButton from '@/components/atoms/Button.vue';
import store from '@/store';
export default defineComponent({
  name: 'Genesis',
  components: {
    NkButton,
    IonContent,
    IonPage,
  },
  setup() {
    const selectedGenesisId = ref(null);
    const dialogVisible = ref(false);
    const selectedItem = ref(null);
    return {
      selectedGenesisId,
      dialogVisible,
      selectedItem,
      currentGenesis: computed(() => {
        return (
          store.getters['providers/geneses/collection'].find(
            (genesis: any) => genesis.id === selectedGenesisId.value,
          ) ||
          store.getters['providers/geneses/collection'][0] ||
          null
        );
      }),
      showItem(item: any) {
        dialogVisible.value = true;
        selectedItem.value = item;
      },
    };
  },
});
