import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "align-top",
  style: {"width":"15.625rem"}
}
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nk_text = _resolveComponent("nk-text")!
  const _component_nk_button = _resolveComponent("nk-button")!
  const _component_nk_table = _resolveComponent("nk-table")!
  const _component_nk_overlay_scrollbars = _resolveComponent("nk-overlay-scrollbars")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_nk_media = _resolveComponent("nk-media")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_nk_navigation_list_item = _resolveComponent("nk-navigation-list-item")!
  const _component_nk_navigation_list = _resolveComponent("nk-navigation-list")!
  const _component_nk_page = _resolveComponent("nk-page")!
  const _component_nk_dialog = _resolveComponent("nk-dialog")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        scrollY: "false"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_nk_page, {
            title: _ctx.$trans('specification.title'),
            "has-overlay": false
          }, {
            tabs: _withCtx(() => [
              _createVNode(_component_nk_navigation_list, { isGrid: "true" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters[
                'providers/specifications/collection'
              ], (specification) => {
                    return (_openBlock(), _createBlock(_component_nk_navigation_list_item, {
                      key: specification.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_nk_button, {
                          onClick: ($event: any) => (_ctx.switchSpecification(specification.id)),
                          active: 
                  _ctx.currentSpecification &&
                  _ctx.currentSpecification.id === specification.id
                ,
                          class: "w-full whitespace-nowrap"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$trans(specification.translations).title), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "active"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_ctx.currentSpecification)
                ? (_openBlock(), _createBlock(_component_ion_grid, {
                    key: _ctx.currentSpecification.id,
                    class: "h-full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, { class: "h-full" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            size: _ctx.selectedMediaId ? '6' : '9'
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_nk_overlay_scrollbars, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_nk_table, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("table", null, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentSpecification.specificationDetail, (spec) => {
                                          return (_openBlock(), _createElementBlock("tr", {
                                            key: spec.id
                                          }, [
                                            _createElementVNode("td", _hoisted_1, [
                                              _createVNode(_component_nk_text, {
                                                type: "xs",
                                                content: _ctx.$trans(spec.translations).title
                                              }, null, 8, ["content"])
                                            ]),
                                            _createElementVNode("td", null, [
                                              _createElementVNode("div", _hoisted_2, [
                                                _createVNode(_component_nk_text, {
                                                  type: "xs",
                                                  content: _ctx.$trans(spec.translations).content
                                                }, null, 8, ["content"]),
                                                (_ctx.$trans(spec.image))
                                                  ? (_openBlock(), _createBlock(_component_nk_button, {
                                                      key: 0,
                                                      onClick: ($event: any) => (_ctx.toggleMedia(_ctx.$trans(spec.image))),
                                                      class: "mx-sm flex-shrink-0",
                                                      type: "icon",
                                                      size: "xs",
                                                      "icon-name": "gallery"
                                                    }, null, 8, ["onClick"]))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ])
                                          ]))
                                        }), 128))
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["size"]),
                          _createVNode(_component_ion_col, {
                            size: _ctx.selectedMediaId ? '6' : '3',
                            class: "h-full"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.selectedMediaId)
                                ? (_openBlock(), _createBlock(_component_nk_media, {
                                    media: _ctx.selectedMediaId,
                                    fit: "contain",
                                    position: "100% 50%",
                                    class: "w-full h-full",
                                    key: _ctx.selectedMediaId,
                                    onClick: _ctx.showDialog
                                  }, null, 8, ["media", "onClick"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["size"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_nk_dialog, {
            modelValue: _ctx.dialogVisible,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = $event)),
            "has-pan-zoom": true
          }, {
            default: _withCtx(() => [
              (_ctx.selectedMediaId)
                ? (_openBlock(), _createBlock(_component_nk_media, {
                    key: _ctx.selectedMediaId,
                    media: _ctx.selectedMediaId,
                    fit: "contain"
                  }, null, 8, ["media"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}