
import Image from './Image.vue';
import Video from './Video.vue';
import { convertBase64DataToBlob, Filesystem } from '@/modules/filesystem';
import { Encoding } from '@capacitor/filesystem';

export default {
  components: {
    Image,
    Video,
  },
  props: {
    media: {
      required: true,
    },
  },
  inject: ['cache'],
  async mounted() {
    /*
     * Since the media comes from the store, it will check if the "localMedia" for that media exists
     * if it does it will get the file and save it in the cache otherwise it will set "src" from the current "media"
     * and will keep checking whenever the component is initialized again. That way "src" will always be set
     * and the local version will be loaded whenever possible
     */
    if (!(this as any).cache.localMedia) {
      const localMedia = (this as any).$store.getters[
        'providers/medias/local/collection'
      ].find((localMedia: any) => localMedia.id === (this as any).media.id);
      if (localMedia) {
        const { path, directory } = localMedia;
        try {
          // Important to define encoding for iOS platform
          const contents = await Filesystem.readFile({
            path,
            directory,
            encoding: Encoding.UTF8,
          });

          const base64Response = await fetch(contents.data);
          (this as any).cache.src = URL.createObjectURL(
            await base64Response.blob(),
          );
          (this as any).cache.localMedia = (this as any).cache.src;
        } catch (e) {
          console.error(e);
        }
      } else {
        (this as any).cache.src = (this as any).media.url;
      }
    }
  },
};
