import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      ($props.content)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: $props.content
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}